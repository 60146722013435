import { type RecursivePartial } from 'dibs-ts-utils/exports/RecursivePartial';

import { setItemInWebStorage, getItemInWebStorageByKey } from '../helpers/webStorage';
import { isJsonObject } from '../helpers/isJsonObject';
import {
    STORAGE_KEYS,
    SUPPORT_CHAT_TOUCH_POINTS,
    DISABLE_AUTH_SUPPORT_CHAT_TOUCH_POINTS,
} from '../constants';

import { type EmbeddedServiceConfigInfo } from './messagingService/types';
import { type SupportChatConfig } from '../types';

type PartialConfig = RecursivePartial<EmbeddedServiceConfigInfo>;
type HasActiveChatValue = 'yes' | 'no';

function storeOrganizationId(organizationId: string): void {
    setItemInWebStorage(STORAGE_KEYS.SUPPORT_CHAT_ORGANIZATION_ID, organizationId);
}
function getOrganizationId(): string | null {
    const organizationId = getItemInWebStorageByKey(STORAGE_KEYS.SUPPORT_CHAT_ORGANIZATION_ID);
    return typeof organizationId === 'string' ? organizationId : null;
}

function storeDeploymentDeveloperName(deploymentDevName: string): void {
    setItemInWebStorage(STORAGE_KEYS.SUPPORT_CHAT_DEPLOYMENT_DEVELOPER_NAME, deploymentDevName);
}
function getDeploymentDeveloperName(): string | null {
    const esDeveloperName = getItemInWebStorageByKey(
        STORAGE_KEYS.SUPPORT_CHAT_DEPLOYMENT_DEVELOPER_NAME
    );
    return typeof esDeveloperName === 'string' ? esDeveloperName : null;
}

function storeSalesforceMessagingUrl(messagingUrl: string): void {
    setItemInWebStorage(STORAGE_KEYS.SUPPORT_CHAT_MESSAGING_URL, messagingUrl);
}
function getSalesforceMessagingUrl(): string | null {
    const messagingUrl = getItemInWebStorageByKey(STORAGE_KEYS.SUPPORT_CHAT_MESSAGING_URL);
    return typeof messagingUrl === 'string' ? messagingUrl : null;
}

function storeJwt(jwt: string): void {
    setItemInWebStorage(STORAGE_KEYS.SUPPORT_CHAT_JWT, jwt);
}
function getJwt(): string | null {
    const jwt = getItemInWebStorageByKey(STORAGE_KEYS.SUPPORT_CHAT_JWT);
    return typeof jwt === 'string' ? jwt : null;
}

function storeDeploymentConfiguration(configuration: PartialConfig | null | undefined): void {
    if (configuration) {
        setItemInWebStorage(
            STORAGE_KEYS.SUPPORT_CHAT_DEPLOYMENT_CONFIGURATION,
            JSON.stringify(configuration)
        );
    }
}
function getDeploymentConfiguration(): PartialConfig | null {
    const configuration = getItemInWebStorageByKey(
        STORAGE_KEYS.SUPPORT_CHAT_DEPLOYMENT_CONFIGURATION
    );
    return configuration ? (configuration as PartialConfig) : null;
}

function getHasActiveChatValue(value: JsonType): HasActiveChatValue {
    return value === 'yes' ? 'yes' : 'no';
}
function storeHasActiveChat(hasActiveChatValue: HasActiveChatValue): void {
    setItemInWebStorage(STORAGE_KEYS.SUPPORT_CHAT_HAS_ACTIVE_CHAT, hasActiveChatValue);
}
function getHasActiveChat(): HasActiveChatValue {
    return getHasActiveChatValue(
        getItemInWebStorageByKey(STORAGE_KEYS.SUPPORT_CHAT_HAS_ACTIVE_CHAT)
    );
}

type SupportChatTouchPoint = SupportChatConfig['touchPoint'];
function getValidSupportChatConfig(config: JsonType): SupportChatConfig | null {
    if (!isJsonObject(config)) {
        return null;
    }

    const touchPoint = config.touchPoint as SupportChatTouchPoint;

    if (SUPPORT_CHAT_TOUCH_POINTS.includes(touchPoint)) {
        return {
            touchPoint,
            startOnLoad: !!config.startOnLoad,
            showChatButtonOnLoad: !!config.showChatButtonOnLoad,
            showWithNoAgents: !!config.showWithNoAgents,
            disableAuth: DISABLE_AUTH_SUPPORT_CHAT_TOUCH_POINTS.includes(touchPoint),
            ...(isJsonObject(config.preChatFields) && { preChatFields: config.preChatFields }),
        };
    }

    return null;
}
function storeSupportChatConfig(config: SupportChatConfig): void {
    setItemInWebStorage(STORAGE_KEYS.SUPPORT_CHAT_CONFIG, config);
}
function getSupportChatConfig(): SupportChatConfig | null {
    return getValidSupportChatConfig(getItemInWebStorageByKey(STORAGE_KEYS.SUPPORT_CHAT_CONFIG));
}

let lastEventId: string | undefined;
function setLastEventId(id: string): void {
    lastEventId = id;
}
function getLastEventId(): string | undefined {
    return lastEventId;
}

let conversationId: string | undefined;
function setConversationId(id: string): void {
    conversationId = id;
}
function getConversationId(): string | undefined {
    return conversationId;
}

function clearInMemoryData(): void {
    conversationId = undefined;
    lastEventId = undefined;
}

export {
    storeOrganizationId,
    getOrganizationId,
    storeDeploymentDeveloperName,
    getDeploymentDeveloperName,
    storeSalesforceMessagingUrl,
    getSalesforceMessagingUrl,
    storeDeploymentConfiguration,
    getDeploymentConfiguration,
    storeHasActiveChat,
    getHasActiveChat,
    getHasActiveChatValue,
    getSupportChatConfig,
    storeSupportChatConfig,
    storeJwt,
    getJwt,
    setLastEventId,
    getLastEventId,
    setConversationId,
    getConversationId,
    clearInMemoryData,
    type HasActiveChatValue,
};
