import { type FC } from 'react';
import { BarHeader } from 'dibs-elements/exports/BarHeader';
import OverlaySpinner from './OverlaySpinner';

import { loadingMessage } from './messages/sharedModalMessages';

import styles from './styles/LoadingModal.scss';

const LoadingModal: FC = () => (
    <div className={styles.bodyMinHeight}>
        <BarHeader title={loadingMessage} />
        <OverlaySpinner />
    </div>
);

export default LoadingModal;
