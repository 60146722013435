import { graphql, commitMutation, type Environment } from 'react-relay/legacy';

import {
    type userProfileMutation as Mutation,
    type EditPersonalProfileInput,
} from './__generated__/userProfileMutation.graphql';

const mutation = graphql`
    mutation userProfileMutation($input: EditPersonalProfileInput!) {
        editPersonalProfile(input: $input) {
            user {
                profile {
                    firstName
                    lastName
                    zipCode
                }
                preferences {
                    currency
                    measurementUnit
                    preferredLocale
                }
            }
        }
    }
`;

type Args = EditPersonalProfileInput & { onCompleted?: () => void; onError?: () => void };

export function userProfileMutation(environment: Environment, args: Args): void {
    const {
        firstName,
        lastName,
        userServiceId,
        zipCode,
        currency,
        measurementUnit,
        preferredLocale,
        onCompleted,
        onError,
    } = args;
    commitMutation<Mutation>(environment, {
        mutation,
        variables: {
            input: {
                firstName,
                lastName,
                userServiceId,
                zipCode,
                currency,
                measurementUnit,
                preferredLocale,
            },
        },
        onCompleted,
        onError,
    });
}
