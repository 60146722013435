import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { IntlProvider } from 'dibs-react-intl';
import serverVars from 'server-vars';
import { localStorage, sessionStorage } from 'dibs-browser-storage';
import {
    GDPR_KEY_AGREED,
    GDPR_KEY_NOT_APPLICABLE,
} from '../components/CookieNotification/constants';
import { shouldDisableNotifications } from '../utils/notificationsHelper';
import { waitForOneTrust } from '../helpers/waitForOneTrust';

let cookieNotificationRoot = null;

export function removeCookieNotification() {
    cookieNotificationRoot?.unmount();
}

const getBuyerCookieNotificationClient = async ({ node, store, relayEnv }) => {
    const isLocalSet = !!localStorage.getItem(GDPR_KEY_AGREED);
    const isSessionSet = !!sessionStorage.getItem(GDPR_KEY_NOT_APPLICABLE);

    if (isSessionSet || isLocalSet || shouldDisableNotifications()) {
        return;
    }

    await waitForOneTrust().catch(() => {
        const cookiePromise = import(
            /* webpackChunkName: "cookieNotification" */ '../components/cookieNotification'
        );

        cookiePromise.then(({ cookieNotificationRootQuery, CookieNotificationRenderer }) => {
            const locale = serverVars.get('locale');
            const messages = serverVars.get('messages');
            cookieNotificationRoot = createRoot(node);
            cookieNotificationRoot.render(
                <IntlProvider locale={locale} messages={messages}>
                    <Provider store={store}>
                        <CookieNotificationRenderer
                            environment={relayEnv}
                            query={cookieNotificationRootQuery}
                        />
                    </Provider>
                </IntlProvider>
            );
        });
    });
};

export { getBuyerCookieNotificationClient };
