import { fetchQuery_DEPRECATED as fetchQuery, graphql, type IEnvironment } from 'relay-runtime';
import { filterNulls } from 'dibs-ts-utils/exports/filterNulls';
import { type ArrayElement } from 'dibs-ts-utils/exports/ArrayElement';

import { type SocialNetworkName } from '../types';
import { type getSocialNetworksQuery } from './__generated__/getSocialNetworksQuery.graphql';

const query = graphql`
    query getSocialNetworksQuery {
        viewer {
            socialNetworks {
                name
                parameters {
                    clientId
                }
            }
        }
    }
`;

type Viewer = NonNullable<getSocialNetworksQuery['response']['viewer']>;
type SocialNetworks = NonNullable<Viewer['socialNetworks']>;
type SocialNetwork = NonNullable<ArrayElement<SocialNetworks>>;

function getSocialNetworks(relayEnvironment: IEnvironment): Promise<SocialNetworks> {
    return fetchQuery<getSocialNetworksQuery>(relayEnvironment, query, {})
        .then(response => {
            return response?.viewer?.socialNetworks || [];
        })
        .catch(() => []);
}

const getSocialNetwork = (function () {
    let socialNetworkPromise: Promise<SocialNetworks> | null;

    return {
        byName: function (
            networkName: SocialNetworkName,
            relayEnvironment: IEnvironment
        ): Promise<SocialNetwork | undefined> {
            if (!socialNetworkPromise) {
                socialNetworkPromise = getSocialNetworks(relayEnvironment);
            }
            return socialNetworkPromise.then(socialNetworks =>
                socialNetworks.filter(filterNulls).find(({ name }) => name === networkName)
            );
        },
    };
})();

export { getSocialNetwork };
