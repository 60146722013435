import {
    type InjectScriptUrl,
    type InjectScriptParams,
    type InjectScriptLocation,
    type InjectScriptAttributes,
} from '../types';

/**
 * Script injector.
 *
 * @param  {String} url    Url of the script.
 * @param  {Object} params Parameters passed to script loading url.
 * @param  {Object} attributes Attributes passed to script loading url.
 * @param  {String} location Script injection location ('head' or 'body').
 * @return {Promise}
 */
export default function injectScript(
    url: InjectScriptUrl,
    {
        params = { async: true },
        attributes = {},
        location = 'head',
    }: {
        params?: InjectScriptParams;
        attributes?: InjectScriptAttributes;
        location?: InjectScriptLocation;
    } = {}
): Promise<void> {
    return new Promise((resolve, reject) => {
        let prop;
        let attribute;

        const script: HTMLScriptElement = document.createElement('script');
        script.src = url;

        for (prop in params) {
            if (params.hasOwnProperty(prop)) {
                // @ts-ignore
                script[prop] = params[prop];
            }
        }

        for (attribute in attributes) {
            if (attributes.hasOwnProperty(attribute)) {
                // @ts-ignore
                script.setAttribute(attribute, attributes[attribute]);
            }
        }

        // @ts-ignore
        if (script.readyState) {
            // @ts-ignore IE
            script.onreadystatechange = function () {
                // @ts-ignore
                if (script.readyState === 'loaded' || script.readyState === 'complete') {
                    // @ts-ignore
                    script.onreadystatechange = null;
                    resolve();
                }
            };
        } else {
            // Others
            script.onload = function () {
                resolve();
            };
            script.onerror = function () {
                reject();
            };
        }

        if (location === 'head') {
            const scriptTag = document.getElementsByTagName('script')[0];

            if (scriptTag && scriptTag.parentNode) {
                scriptTag.parentNode.insertBefore(script, scriptTag);
            }
        } else if (location === 'body') {
            window.document.body.appendChild(script);
        }
    });
}

export const injectCss = (href: string): void => {
    const cssLink = window.document.createElement('link') as HTMLLinkElement;
    cssLink.type = 'text/css';
    cssLink.rel = 'stylesheet';
    cssLink.href = href;
    const parent = window.document.getElementsByTagName('head')[0];
    const firstLink = parent.getElementsByTagName('link')[0];
    parent.insertBefore(cssLink, firstLink);
};
