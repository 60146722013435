import dibsCss from 'dibs-css';
import classnames from 'classnames';
import { type FC } from 'react';

type LogoVipEsProps = {
    className?: string;
    role?: 'img';
    ariaLabel?: string;
    ariaLabelledby?: string;
};

const LogoVipEs: FC<LogoVipEsProps> = props => {
    return (
        <svg
            className={classnames(dibsCss.dibsIcon, props.className)}
            data-tn={'logo-vip-es'}
            role={props.role}
            aria-label={props.ariaLabel}
            aria-labelledby={props.ariaLabelledby}
            id="Layer_1"
            data-name="Layer 1"
            viewBox="0 0 30 13.86"
        >
            <defs>
                <clipPath id="clippath">
                    <rect width="30" height="13.86" className="cls-2" />
                </clipPath>
            </defs>
            <path d="M14.03 5.92h.5v1.71c0 .52.25.81.68.81.22 0 .43-.07.61-.19s.32-.29.41-.49l-.03-.04c-.18.17-.41.27-.65.29-.35 0-.47-.17-.47-.61V5.92h.96l.1-.43h-1.07v-.64l-1.03 1v.07zm.93-4.32c.34.06.64.24.85.52v-.6c-.24-.13-.52-.2-.79-.21-.11-.01-.23 0-.34.04-.11.04-.21.09-.29.17-.09.08-.15.17-.2.27-.05.1-.07.22-.07.33.01.16.06.32.15.45.09.14.21.25.35.32l.52.35c.25.17.33.28.33.47 0 .05-.01.1-.03.15-.02.05-.05.09-.09.12a.37.37 0 01-.14.07c-.05.01-.1.02-.15.01-.31 0-.56-.22-.92-.61v.65c.24.18.53.27.84.28.24 0 .48-.07.66-.24.18-.16.29-.39.31-.63a.996.996 0 00-.17-.49.961.961 0 00-.39-.33l-.46-.31c-.21-.14-.33-.26-.33-.46 0-.05.01-.1.03-.14.02-.04.05-.08.08-.12.04-.03.08-.06.12-.07.05-.02.09-.02.14-.02zm-2.81 1.23v4.41c0 .75-.32.96-.98 1.18h2.79c-.65-.22-.98-.43-.98-1.18V2.12c0-.34.01-.69.06-1.03-.62.41-1.32.67-2.06.75v.13c1 0 1.17.26 1.17.86zM13.86 0h-2.8v.35h2.62c2.92 0 4.14 1.84 4.14 4.46 0 3.02-1.3 4.6-3.87 4.6h-2.84v.35h2.87c3.69 0 5.03-2.51 5.03-4.94 0-2.35-1.42-4.8-5.14-4.8z" />
            <path d="M16.72 11.88h-.61l.06.04s.08.06.1.1c.02.04.03.09.03.14v1.41s0 .1-.03.14c-.02.04-.06.08-.1.1l-.06.04h.7l-.06-.04s-.08-.06-.1-.1-.03-.09-.03-.14v-.59h.14c.41 0 .67-.22.67-.56s-.26-.54-.71-.54zm-.1.18h.07c.25 0 .41.14.41.36 0 .05 0 .1-.02.15a.38.38 0 01-.22.21c-.05.02-.1.02-.15.02h-.08v-.74z" />
            <path d="M19.43 13.48s-.04-.06-.06-.1c-.09-.18-.22-.34-.37-.47.12-.02.23-.08.31-.17.08-.09.12-.21.13-.33 0-.32-.29-.53-.73-.53h-.63l.07.04s.08.06.1.1c.02.04.03.09.03.14v1.41s0 .1-.03.14c-.02.04-.06.08-.1.1l-.06.04h.7l-.06-.04s-.08-.06-.1-.1a.32.32 0 01-.03-.14v-.6c.12 0 .2.09.32.29l.36.58.45.01v-.05h-.02c-.09-.02-.17-.12-.29-.31zm-.33-1.07c0 .31-.24.37-.45.37h-.06v-.72h.05c.23 0 .45.11.45.35z" />
            <path d="M21 11.92l.06-.04h-.7l.06.04s.08.06.1.1c.02.04.03.09.03.14v1.41s0 .1-.03.14-.06.08-.1.1l-.06.04h.7l-.06-.04s-.08-.06-.1-.1a.32.32 0 01-.03-.14v-1.41s0-.1.03-.14c.02-.04.06-.08.1-.1z" />
            <path d="M22.93 11.92s.05.04.07.06c.02.03.03.06.03.09 0 .05-.01.1-.03.14l-.4 1.07-.38-1.06a.471.471 0 01-.04-.17c0-.03 0-.06.02-.08l.06-.06.07-.04h-.69l.05.04c.09.07.15.16.17.27l.62 1.66.11.01.64-1.68c.03-.1.1-.19.18-.26l.07-.04h-.65l.08.04z" />
            <path d="M25.25 13.53l-.62-1.66-.11-.01-.64 1.68c-.03.1-.1.19-.18.26l-.07.04h.65l-.08-.04s-.05-.04-.07-.06a.161.161 0 01-.03-.09c0-.05.01-.1.03-.14l.13-.36h.51l.13.35c.02.05.04.11.04.17 0 .03 0 .06-.02.08l-.06.06-.07.04h.69l-.05-.04a.436.436 0 01-.17-.27zm-.55-.6h-.36l.18-.5.18.5z" />
            <path d="M1.07 11.83c-.14 0-.27.02-.4.07s-.24.12-.34.22c-.1.09-.17.21-.23.33-.05.12-.08.26-.08.39s.03.27.08.39.13.24.23.33c.1.09.21.17.34.22.13.05.26.07.4.07.21 0 .43-.05.62-.15h.01v-.44l-.04.05c-.09.13-.22.23-.37.28-.15.05-.31.04-.46-.01a.717.717 0 01-.35-.29.707.707 0 01-.09-.45c-.01-.1 0-.2.02-.29.03-.09.08-.18.14-.26.06-.07.14-.13.23-.18a.706.706 0 01.87.25l.04.05v-.43h-.01c-.19-.1-.4-.15-.62-.15z" />
            <path d="M28.55 13.78a.949.949 0 01-.34-.22c-.1-.09-.17-.21-.23-.33s-.08-.26-.08-.39.03-.27.08-.39.13-.24.23-.33c.1-.09.21-.17.34-.22.13-.05.26-.07.4-.07s.27.02.4.07.24.12.34.22c.1.09.17.21.23.33.05.12.08.26.08.39s-.03.27-.08.39-.13.24-.23.33c-.1.09-.21.17-.34.22-.13.05-.26.07-.4.07s-.27-.02-.4-.07zm.4-.15c.08 0 .16-.02.23-.04.15-.06.27-.16.35-.29.08-.14.11-.29.09-.45.01-.1 0-.2-.02-.29a.939.939 0 00-.14-.26.836.836 0 00-.23-.18.706.706 0 00-.28-.06.639.639 0 00-.51.24c-.06.07-.11.16-.14.26-.03.09-.04.19-.02.29-.02.16.01.31.09.45a.66.66 0 00.58.33z" />
            <path d="M3.3 13.62h-.39v-1.47s0-.1.03-.14c.02-.04.06-.08.1-.1l.06-.04h-.7l.06.04s.08.06.1.1c.02.04.03.09.03.14v1.41s0 .1-.03.14-.06.08-.1.1l-.06.04h1.23l.09-.35-.05.04c-.11.08-.24.11-.37.1z" />
            <path d="M5 11.91l.06-.04h-.7l.06.04s.08.06.1.1c.02.04.03.09.03.14v1.41s0 .1-.03.14c-.02.04-.06.08-.1.1l-.06.04h.7L5 13.8s-.08-.06-.1-.1a.32.32 0 01-.03-.14v-1.41s0-.1.03-.14c.02-.04.06-.08.1-.1z" />
            <path d="M6.72 13.62h-.33v-.7h.5c.04 0 .08.03.11.06l.04.04v-.43l-.04.04s-.07.05-.11.06c-.04.01-.09.02-.13 0h-.37v-.64h.24c.14-.02.29.02.41.11l.04.03v-.35h-1.2l.06.04s.08.06.1.1c.02.04.03.09.03.14v1.41s0 .1-.03.14-.06.08-.1.1l-.06.04h1.18l.09-.35-.05.04c-.11.08-.24.11-.37.1z" />
            <path d="M13.39 13.62h-.33v-.7h.5c.04 0 .08.03.11.06l.04.04v-.43l-.04.04s-.07.05-.11.06c-.04.01-.09.02-.13 0h-.37v-.64h.24c.14-.02.29.02.41.11l.04.03v-.35h-1.2l.06.04s.08.06.1.1c.02.04.03.09.03.14v1.41s0 .1-.03.14-.06.08-.1.1l-.06.04h1.18l.09-.35-.05.04c-.11.08-.24.11-.37.1z" />
            <path d="M9.23 11.91c.09.06.13.11.13.27v.93l-.86-1.23h-.53l.06.04s.08.06.1.1c.02.04.03.09.03.14v1.38c0 .16-.04.21-.13.27l-.06.04h.68l-.09-.04c-.11-.05-.15-.1-.15-.27v-1.26l1.14 1.59h.07v-1.69c0-.16.04-.21.13-.27l.06-.04h-.64l.06.04z" />
            <path d="M10.44 11.87v.39l.05-.04c.11-.1.25-.16.4-.16h.12v1.49s0 .1-.03.14c-.02.04-.06.08-.1.1l-.06.04h.7l-.06-.04s-.08-.06-.1-.1a.32.32 0 01-.03-.14v-1.49h.11c.15 0 .29.05.4.16l.05.04v-.39h-1.44z" />
            <path d="M26.56 11.88h-.63l.07.04c.04.02.08.06.1.1.02.04.03.09.03.14v1.41s0 .1-.03.14c-.02.04-.06.08-.1.1l-.06.04h.7l-.06-.04s-.08-.06-.1-.1a.32.32 0 01-.03-.14v-1.5l.12-.18z" />
            <path d="M26.54 13.84a1.078 1.078 0 00.74-.28c.1-.09.17-.2.23-.32.05-.12.08-.25.08-.38s-.03-.26-.08-.38a.902.902 0 00-.23-.32 1.078 1.078 0 00-.74-.28l-.33.29c.1-.05.22-.08.33-.08.1 0 .2.02.28.06.09.04.17.1.23.17s.11.16.14.25c.03.09.04.19.02.28.02.15-.01.31-.09.44s-.21.23-.35.29c-.15.05-.31.06-.46.01l.22.25z" />
        </svg>
    );
};

export default LogoVipEs;
