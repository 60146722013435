import { useContext, useEffect, type FunctionComponent, type ReactNode } from 'react';
import { Bar } from '../Bar';
import { Header } from '../Header';
import classnames from 'classnames';
import { ModalContext } from '../ModalContainer/modalContext';
import { type HeadingType } from 'dibs-controlled-heading/exports/HeadingLevel';
import dibsCss from 'dibs-css';

type BarHeaderProps = {
    size?: 'small' | 'medium' | 'large' | 'xLarge' | 'xxLarge';
    dataTn?: string;
    title: ReactNode;
    subtitle?: ReactNode;
    htmlElementType?: 'div' | 'h1' | 'h2' | HeadingType;
    type?: 'trade' | 'default';
    id?: string;
    className?: string;
};

export const BarHeader: FunctionComponent<BarHeaderProps> = ({
    size = 'large',
    title,
    subtitle,
    dataTn,
    htmlElementType = 'div',
    type = 'default',
    className,
}) => {
    const { labelId, setLabelActive } = useContext(ModalContext);
    useEffect(() => {
        // ensure UID is active on render
        setLabelActive(true);
        // deactivate UID on unmount
        return () => setLabelActive(false);
    }, [setLabelActive]);
    return (
        <div
            className={classnames(
                dibsCss.pLarge,
                dibsCss.pb0,
                dibsCss.textNoir,
                dibsCss.textCenter,
                {
                    [dibsCss.mbSmall]: size === 'small',
                    [dibsCss.mbMedium]: size === 'medium',
                    [dibsCss.mbLarge]: ['large', 'xLarge', 'xxLarge'].includes(size),
                },
                className
            )}
            data-tn={`bar-header${dataTn ? `-${dataTn}` : ''}`}
        >
            <Header
                id={labelId || undefined}
                title={title}
                subtitle={subtitle}
                htmlElementType={htmlElementType}
                size={size}
                dataTn={dataTn}
            />
            <Bar size={size} type={type} />
        </div>
    );
};
