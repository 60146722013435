import { createId } from '@paralleldrive/cuid2';
import { localStorage } from 'dibs-browser-storage';
import { cookieJarConstants, getBuyerId } from 'dibs-cookie-jar';
import { ClientCookieManager } from 'dibs-consented-storage';

// As of Apple ITP 2.1, guestIds are stored in localStorage
// See: https://1stdibs.atlassian.net/browse/ENGAGE-979
const getGuestIdFromLocalStorage = (): string => {
    const guestId = localStorage.getItem(cookieJarConstants.GUEST_ID);
    return typeof guestId === 'string' ? guestId : '';
};

const getGuestIdFromCookie = (): string => {
    const guestId = ClientCookieManager.get(cookieJarConstants.GUEST_ID);
    return typeof guestId === 'string' ? guestId : '';
};

export const getGuestId = (): string => {
    return getGuestIdFromCookie() || getGuestIdFromLocalStorage();
};

export const handleGuestId = (): void => {
    if (!getBuyerId(document.cookie)) {
        let guestIdFromLocalStorage = getGuestIdFromLocalStorage();
        const guestIdFromCookie = getGuestIdFromCookie();
        if (!guestIdFromLocalStorage) {
            guestIdFromLocalStorage = createId();
            localStorage.setItem(cookieJarConstants.GUEST_ID, guestIdFromLocalStorage);
        }
        if (!guestIdFromCookie) {
            ClientCookieManager.set(cookieJarConstants.GUEST_ID, guestIdFromLocalStorage);
        }
    }
};
