export const REGISTER = 'register' as const;
export const REGISTRATION = 'registration' as const;
export const LOGIN = 'login' as const;
export const REQUEST_PASSWORD_EMAIL = 'request-password-email' as const;
export const RESET_PASSWORD = 'reset-password' as const;
export const UPDATE_PASSWORD = 'update-password' as const;
export const SOCIAL_ACCOUNT_LINKED = 'social-account-linked' as const;
export const AUTO_PROMPT_REGISTER = 'AUTO_PROMPT_REGISTER' as const;
export const SOCIAL_CASE_GOOGLE_ONE_TAP_LOGIN = 'GOOGLE_ONE_TAP_LOGIN' as const;
export const SOCIAL_CASE_CONNECTION_LINKED = 'LINKED_SOCIAL_CONNECTION' as const;
export const SOCIAL_CASE_ACCOUNT_CREATED = 'Created' as const;
export const SOCIAL_CASE_NO_EMAIL_RETURNED = 'NO_EMAIL_RETURNED' as const;
export const SOCIAL_CASE_TOKEN_EXPIRED = 'SOCIAL_CASE_TOKEN_EXPIRED' as const;
export const SSO = 'SSO' as const;
// types of auth consumption
export const AUTH_CONTENT = 'AUTH_CONTENT' as const; // auth injected as content
export const AUTH_MODAL = 'AUTH_MODAL' as const; // auth loaded as modal

//checkout flows
export const CHECKOUT_SSO_FLOW = 'CHECKOUT_SSO_FLOW' as const;

// login and registration flows
export const DEFAULT_FLOW = 'DEFAULT_FLOW' as const;
export const DEFAULT_UNAUTH_FLOW = 'DEFAULT_UNAUTH_FLOW' as const;
export const PAGEVIEW_LIMIT_FLOW = 'PAGEVIEW_LIMIT_FLOW' as const;
export const COMPLETE_EMAIL_ONLY_FLOW = 'COMPLETE_EMAIL_ONLY_FLOW' as const;
export const CONTACT_DEALER_FLOW = 'CONTACT_DEALER_FLOW' as const;
export const FULL_ACCOUNT_CONTACT_DEALER_FLOW = 'FULL_ACCOUNT_CONTACT_DEALER_FLOW' as const;
export const CALL_DEALER_FLOW = 'CALL_DEALER_FLOW' as const;
export const EMBEDDED_CHECKOUT_FLOW = 'EMBEDDED_CHECKOUT_FLOW' as const;
export const GUEST_CHECKOUT_ACCOUNT_EXISTS_FLOW = 'GUEST_CHECKOUT_ACCOUNT_EXISTS_FLOW' as const;
export const EXPRESS_CHECKOUT_ACCOUNT_EXISTS_SUBMIT_FLOW =
    'EXPRESS_CHECKOUT_ACCOUNT_EXISTS_SUBMIT_FLOW' as const;
export const GUEST_CHECKOUT_EMAIL_LINK_SENT_FLOW = 'GUEST_CHECKOUT_EMAIL_LINK_SENT_FLOW' as const;
export const EMAIL_LINK_SENT_FLOW = 'EMAIL_LINK_SENT_FLOW' as const;
export const EMBEDDED_PROMO_FLOW = 'EMBEDDED_PROMO_FLOW' as const;
export const EMAIL_LINK_LOGIN_EMBEDDED_CHECKOUT_FLOW =
    'EMAIL_LINK_LOGIN_EMBEDDED_CHECKOUT_FLOW' as const;
export const FOLLOW_DEALER_FLOW = 'FOLLOW_DEALER_FLOW' as const;
export const SAVE_ITEM_FLOW = 'SAVE_ITEM_FLOW' as const;
export const SAVE_ITEM_TO_FOLDER_FLOW = 'SAVE_ITEM_TO_FOLDER_FLOW' as const;
export const STALE_LOGIN_FLOW = 'STALE_LOGIN_FLOW' as const;
export const STALE_LOGIN_EMAIL_ONLY_CHECKOUT_FLOW = 'STALE_LOGIN_EMAIL_ONLY_CHECKOUT_FLOW' as const;
export const EMAIL_ONLY_EXPRESS_CHECKOUT_SUBMIT_FLOW =
    'EMAIL_ONLY_EXPRESS_CHECKOUT_SUBMIT_FLOW' as const;
export const STALE_LOGIN_CHECKOUT_FLOW = 'STALE_LOGIN_CHECKOUT_FLOW' as const;
export const TRADE_APPLICATION_FLOW = 'TRADE_APPLICATION_FLOW' as const;
export const SUSPECTED_TRADE_APPLICATION = 'SUSPECTED_TRADE_APPLICATION' as const;
export const SUSPECTED_TRADE_APPLICATION_PASSWORD = 'SUSPECTED_TRADE_APPLICATION_PASSWORD' as const;
export const ALT_REGISTRATION_CONSUMER_FLOW = 'ALT_REGISTRATION_CONSUMER_FLOW' as const;
export const REQUEST_SHIPPING_FLOW = 'REQUEST_SHIPPING_FLOW' as const;
export const DESIGNER_CONNECTION_MODAL_FLOW = 'DESIGNER_CONNECTION_MODAL_FLOW' as const;
export const DISCOVER_FEED_FLOW = 'DISCOVER_FEED_FLOW' as const;
export const SAVE_SUBSCRIPTION_PREFERENCES_FLOW = 'SAVE_SUBSCRIPTION_PREFERENCES_FLOW' as const;
export const AUTO_MODAL_PDP_FLOW = 'AUTO_MODAL_PDP_FLOW' as const;
export const LOGGED_OUT_TRADE_FLOW = 'LOGGED_OUT_TRADE_FLOW' as const;
export const MAKE_OFFER_FLOW = 'MAKE_OFFER_FLOW' as const;
export const EMAIL_SHARE_PDP_FLOW = 'EMAIL_SHARE_PDP_FLOW' as const;
export const LIKE_PHOTO_FLOW = 'LIKE_PHOTO_FLOW' as const;
export const CONTACT_1STDIBS_FLOW = 'CONTACT_1STDIBS_FLOW' as const;
export const BID_AUCTION_ITEM_FLOW = 'BID_AUCTION_ITEM_FLOW' as const;
export const EMAIL_LINK_LOGIN_FLOW = 'EMAIL_LINK_LOGIN_FLOW' as const;
export const EMAIL_LINK_LOGIN_RESEND_EMAIL_FLOW = 'EMAIL_LINK_LOGIN_RESEND_EMAIL_FLOW' as const;
export const EMAIL_LINK_LOGIN_STALE_USER_FLOW = 'EMAIL_LINK_LOGIN_STALE_USER_FLOW' as const;
export const SOCIAL_LOGIN_MARKETING = 'SOCIAL_LOGIN_MARKETING' as const;
export const GOOGLE_ONE_TAP_FLOW = 'GOOGLE_ONE_TAP_FLOW' as const;
export const INVOICE_LINK_FLOW = 'INVOICE_LINK_FLOW' as const;
export const GATE_SOLD_PRICE_FLOW = 'GATE_SOLD_PRICE_FLOW' as const;

// nft auth flow
export const NFT_BID_FLOW = 'NFT_BID_FLOW';
export const NFT_CONNECT_WALLET_FLOW = 'NFT_CONNECT_WALLET_FLOW';
export const LIKE_NFT_ITEM_FLOW = 'LIKE_NFT_ITEM_FLOW';

// Log in with email token
export const LOGIN_EMAIL_TOKEN_DEFAULT_FLOW = 'LOGIN_EMAIL_TOKEN_DEFAULT_FLOW' as const;
export const LOGIN_EMAIL_TOKEN_MY_ORDERS_FLOW = 'LOGIN_EMAIL_TOKEN_MY_ORDERS_FLOW' as const;
export const LOGIN_EMAIL_TOKEN_MY_CONVERSATIONS_FLOW =
    'LOGIN_EMAIL_TOKEN_MY_CONVERSATIONS_FLOW' as const;

// forgot password flows (modal which sends reset password email)
export const RESET_PASSWORD_EMAIL_FLOW = 'RESET_PASSWORD_EMAIL_FLOW' as const;
export const EMAIL_ONLY_CREATE_PASSWORD_FLOW = 'EMAIL_ONLY_CREATE_PASSWORD_FLOW' as const;
export const RESET_INVALID_RESET_PASSWORD_FLOW = 'RESET_INVALID_RESET_PASSWORD_FLOW' as const;

// reset password + reset invalid flows (modal which allows inline reset of password)
export const DEFAULT_RESET_PASSWORD_FLOW = 'DEFAULT_RESET_PASSWORD_FLOW' as const;
export const CREATE_PASSWORD_MY_CONVERSATIONS_FLOW =
    'CREATE_PASSWORD_MY_CONVERSATIONS_FLOW' as const;
export const CREATE_PASSWORD_MY_ORDERS_FLOW = 'CREATE_PASSWORD_MY_ORDERS_FLOW' as const;
export const CREATE_PASSWORD_ACCEPT_OFFER_FLOW = 'CREATE_PASSWORD_ACCEPT_OFFER_FLOW' as const;
export const CREATE_PASSWORD_REQUEST_SHIPPING_MC_FLOW =
    'CREATE_PASSWORD_REQUEST_SHIPPING_MC_FLOW' as const;
export const CREATE_PASSWORD_INVOICE_FLOW = 'CREATE_PASSWORD_INVOICE_FLOW' as const;
export const CREATE_PASSWORD_CALL_SELLER_FLOW = 'CREATE_PASSWORD_CALL_SELLER_FLOW' as const;

// existing email only users flows
export const COMPLETE_SIGNUP_VERIFY_EMAIL_FLOW = 'COMPLETE_SIGNUP_VERIFY_EMAIL_FLOW' as const;
export const DEFAULT_VERIFY_EMAIL_FLOW = 'DEFAULT_VERIFY_EMAIL_FLOW' as const;

// close reasons
export const CLOSE = 'closed' as const;
export const PAUSE_AUTH_FLOW = 'PAUSE_AUTH_FLOW' as const;
export const CLOSED_BY_CONSUMER = 'CLOSED_BY_CONSUMER' as const;

// flows which have specific handling for the verify email flow
export const verifyEmailFlows = [
    CONTACT_DEALER_FLOW,
    SAVE_ITEM_FLOW,
    SAVE_ITEM_TO_FOLDER_FLOW,
    FOLLOW_DEALER_FLOW,
    MAKE_OFFER_FLOW,
    COMPLETE_SIGNUP_VERIFY_EMAIL_FLOW,
    DEFAULT_VERIFY_EMAIL_FLOW,
    NFT_BID_FLOW,
    NFT_CONNECT_WALLET_FLOW,
] as const;
