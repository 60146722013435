/**
 * @generated SignedSource<<bbe4dfd65c11dbd0103f6b9ff4320400>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type Currency = "AUD" | "CAD" | "CHF" | "DKK" | "EUR" | "GBP" | "JPY" | "MXN" | "NOK" | "SEK" | "USD" | "%future added value";
export type DesignProfileStatus = "ACTIVE" | "DELETED" | "NEW" | "PENDING" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type userTracking_user$data = {
  readonly activePromotion: {
    readonly buyerDisplayable: boolean | null;
    readonly campaignName: string | null;
  } | null;
  readonly authMetadata: {
    readonly emailOnly: boolean | null;
    readonly hasPassword: boolean | null;
  } | null;
  readonly createdDate: string | null;
  readonly isVerifiedTrade: boolean | null;
  readonly isVip: boolean | null;
  readonly preferences: {
    readonly currency: Currency | null;
  } | null;
  readonly profile: {
    readonly email: string | null;
    readonly emailMd5: string | null;
    readonly emailSha256: string | null;
    readonly zipCode: string | null;
  } | null;
  readonly serviceId: string | null;
  readonly totalMessagesToDealers: number | null;
  readonly tradeFirm: {
    readonly profileStatus: DesignProfileStatus | null;
    readonly rewards: {
      readonly currentProgram: {
        readonly programInfo: {
          readonly code: string | null;
        } | null;
      } | null;
    } | null;
    readonly serviceId: string | null;
  } | null;
  readonly verifiedTradeStatus: string | null;
  readonly " $fragmentType": "userTracking_user";
};
export type userTracking_user$key = {
  readonly " $data"?: userTracking_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"userTracking_user">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "serviceId",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "userTracking_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isVip",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isVerifiedTrade",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "verifiedTradeStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserExistsMetadataType",
      "kind": "LinkedField",
      "name": "authMetadata",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "emailOnly",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hasPassword",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Profile",
      "kind": "LinkedField",
      "name": "profile",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "email",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "zipCode",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "emailMd5",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "emailSha256",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserPreferences",
      "kind": "LinkedField",
      "name": "preferences",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "currency",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalMessagesToDealers",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TradeFirmType",
      "kind": "LinkedField",
      "name": "tradeFirm",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "profileStatus",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "TradeFirmRewards",
          "kind": "LinkedField",
          "name": "rewards",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ParentProgram",
              "kind": "LinkedField",
              "name": "currentProgram",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "LoyaltyProgram",
                  "kind": "LinkedField",
                  "name": "programInfo",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "code",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Promotion",
      "kind": "LinkedField",
      "name": "activePromotion",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "campaignName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "buyerDisplayable",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};
})();

(node as any).hash = "139f9b004e4e3e73aef85a71f4765ef0";

export default node;
