import { type MouseEvent } from 'react';
import { trackEvent, eventNameConstants, interactionTypeConstants } from 'dibs-tracking';
const { EVENT_NAVIGATION } = eventNameConstants;
const { INTERACTION_TYPE_GLOBAL_NAV } = interactionTypeConstants;

/**
 * Formats date string to MM-YYYY used by GA.
 */
export function formatDate(dateString?: string): string | undefined {
    if (dateString) {
        const date = new Date(dateString);

        if (!isNaN(date.getTime())) {
            return `${date.getMonth() + 1}-${date.getFullYear()}`;
        }
    }

    return undefined;
}

export const trackNavigationClick = (
    {
        label,
        additional,
        isInteractiveEvent,
        isHeader,
    }: {
        label?: string;
        isInteractiveEvent?: boolean;
        additional?: Record<string, string>;
        isHeader?: boolean;
    },
    event: MouseEvent | null
): void => {
    const trackingLabel = (label || '').trim().toLowerCase();
    const action = `${isHeader ? 'header' : 'footer'} navigation click`;
    trackEvent(
        {
            category: 'navigation',
            action,
            label: trackingLabel,
            isInteractiveEvent,
            additional,
            eventName: EVENT_NAVIGATION,
            interaction_type: INTERACTION_TYPE_GLOBAL_NAV,
            step_interaction_name: action,
            trigger: trackingLabel,
        },
        event
    );
};
