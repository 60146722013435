import serverVars from 'server-vars';
import { type RumGlobal } from '@datadog/browser-rum';
import { setDatadogUserValues } from './setDatadogValues';
import { stack as datadogViewStack } from './datadogStack';
import { PAGE_TYPE } from 'dibs-constants/exports/pageTypes';

export const pdpTypes = {
    marketplace: 'marketplace',
    unavailable: 'unavailable',
    condensed: 'condensed',
    private: 'private',
} as const;

export const sbTypes = {
    search: PAGE_TYPE.SEARCH,
    buy: PAGE_TYPE.BUY,
    browse: PAGE_TYPE.BROWSE,
    similarSold: 'similar',
    visuallySimilar: 'similar',
} as const;

const pageData = {
    [PAGE_TYPE.PDP]: { name: PAGE_TYPE.PDP, type: 'dibsPdpType', sampleRateKey: 'pdpSampleRate' },
    [PAGE_TYPE.BROWSE]: {
        name: PAGE_TYPE.BROWSE,
        type: 'dibsSbType',
        sampleRateKey: 'sbSampleRate',
    },
    [PAGE_TYPE.ORDER_MANAGER]: {
        name: PAGE_TYPE.ORDER_MANAGER,
        type: 'dibsBomtType',
        sampleRateKey: 'bomtSampleRate',
    },
} as const;

type DeviceType = 'resp' | 'mobile';
type SbType = (typeof sbTypes)[keyof typeof sbTypes];
type PdpType = (typeof pdpTypes)[keyof typeof pdpTypes];

export const initDatadogRum = (datadog: RumGlobal | null): void => {
    const DD_RUM = serverVars.get('DD_RUM');

    if (!DD_RUM || !datadog) {
        return;
    }

    datadog.init({
        applicationId: '89e9a510-3de5-4df0-8d4e-d460f880e9cb',
        clientToken: 'pubc2eadb012ee8437b00287cd23fafa737',
        site: 'datadoghq.com',
        env: DD_RUM.env,
        sessionSampleRate: 100,
        trackResources: true,
        sessionReplaySampleRate: 100,
        trackUserInteractions: true,
        trackFrustrations: true,
        actionNameAttribute: 'data-tn',
        defaultPrivacyLevel: 'mask-user-input',
        trackViewsManually: true,
    });

    datadog.startSessionReplayRecording();
};

export const initSharedDatadogRum = (
    datadog: RumGlobal | null,
    {
        page,
        device,
        pageSubType,
    }:
        | { device: DeviceType; page: typeof PAGE_TYPE.PDP; pageSubType: PdpType }
        | { device: DeviceType; page: typeof PAGE_TYPE.BROWSE; pageSubType: SbType }
        | { device: DeviceType; page: typeof PAGE_TYPE.ORDER_MANAGER; pageSubType: 'bomt' }
): void => {
    const DD_RUM = serverVars.get('DD_RUM');

    if (!DD_RUM || !datadog) {
        return;
    }

    const { name, sampleRateKey, type } = pageData[page];
    const sampleRate = (DD_RUM[sampleRateKey] || 0) / 100;
    const viewConfig = {
        name,
        service: DD_RUM.service,
        version: DD_RUM.version,
    };

    datadogViewStack.push(viewConfig);

    if (Math.random() >= sampleRate) {
        return;
    }

    initDatadogRum(datadog);
    setDatadogUserValues(datadog);

    datadog.startView(viewConfig);
    datadog.setGlobalContextProperty('dibsDevice', device);
    datadog.setGlobalContextProperty(type, pageSubType);
    datadog.setGlobalContextProperty('dibsLocale', serverVars.get('locale'));
};
