import { getAdminMasqueradingAsBuyerUserId, getGuestId } from 'dibs-cookie-jar';
import { removeLocaleSegment } from 'dibs-intl/exports/urls';

import { type UserTrackingItemInputType } from './__generated__/trackUserEventMutation.graphql';

export const getPageId = (): string =>
    `${removeLocaleSegment(window.location.pathname)}${window.location.search}`;

export const getUserData = (): { userId: string; sessionId: string } | null => {
    const guestId = getGuestId(document.cookie) || '';
    const userId = getAdminMasqueradingAsBuyerUserId(document.cookie) || guestId;

    return userId ? { userId, sessionId: guestId } : null;
};

export type userEventItem = UserTrackingItemInputType;

export const getUserEventItem = (args: {
    id: unknown;
    amount: unknown;
    currency?: string | null;
    quantity?: number | null;
}): userEventItem | null => {
    const { id, amount } = args;
    if (typeof id === 'string' && id && typeof amount === 'number' && amount) {
        const currency = args.currency || 'USD';
        const quantity = args.quantity || 1;

        return {
            id,
            quantity,
            price: {
                amount,
                currency,
            },
        };
    }
    return null;
};
