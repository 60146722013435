import { useState, useEffect } from 'react';
import { getUserSessionRegions, type RegionsByZipCode } from './regionalInfoHelpers';

//Constant for identifying if we need to wait for regions to populate.
export const WAIT_FOR_SHIPPING_REGIONS = null;

/**
 * Used to read regions from sessionStorage only after hydration step has finished.
 */
export function useUserSessionRegions(): RegionsByZipCode[] | undefined | null {
    /**
     * Set initial regions to null in order to identify if we have yet to populate the value
     * When populated can be undefined or an array.
     */
    const [regions, setRegions] = useState<RegionsByZipCode[] | undefined | null>(null);

    /**
     * `getUserSessionsRegions` always returns a new instance of `userRegions`, so we
     * try to always return the same reference when the contents of the object didn't
     * change, making it easier to use this hooks with the likes of useEffect or useMemo
     */
    const userRegionsString = JSON.stringify(getUserSessionRegions());

    useEffect(() => {
        const userRegions = getUserSessionRegions();
        setRegions(userRegions);
    }, [userRegionsString]);

    return regions;
}
