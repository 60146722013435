import {
    type MouseEventHandler,
    type KeyboardEventHandler,
    type FocusEventHandler,
    type FC,
    type MutableRefObject,
    type ReactNode,
} from 'react';
import { useIntl } from 'dibs-react-intl';
import classnames from 'classnames';
import styles from './main.scss';
import { handleLocaleUrl } from 'dibs-intl/exports/urls';

export type BuyerLinkType =
    | 'standardLink'
    | 'invertedLink'
    | 'contrastLinkLightInverse'
    | 'contrastLinkLight'
    | 'contrastLinkAlternate'
    | 'contrastLinkNoEffect'
    | 'standardLinkNoEffect'
    | 'linkNoDecoration';

type Underline = 'none' | 'underline' | 'dashed';

// only use Role if you know you need it, otherwise the implicit roles that
// button (role=button) and anchor (role=link) are fine
type Roles =
    | 'menuitem' // use if container has role="menu"
    | 'link' // use if no href but onClick navigates with window.location
    | 'tab'; // use in aria tab widget (dibs-elements Tabs)

type AnchorProps = {
    rel?: string;
    href?: string;
    target?: '_blank';
    download?: boolean | string;
    title?: string;
};

type AnchorExternalProps = Omit<AnchorProps, 'rel'>;

type NeverAnchorExternalProps = { [P in keyof AnchorExternalProps]?: never };

interface BaseProps {
    children?: ReactNode;
    className?: string;
    dataTn?: string;
    dataAction?: string;
    buyerLinkType?: BuyerLinkType;
    underline?: Underline;
    id?: string;
    tabIndex?: number;
    noFollow?: boolean;
    // ref provider needs runtime checks for anchor or button if using
    // attributes specific to either
    linkRef?:
        | ((node: HTMLAnchorElement | HTMLButtonElement | null) => void)
        | MutableRefObject<HTMLAnchorElement | HTMLButtonElement>;
    // event handlers
    onMouseEnter?: MouseEventHandler;
    onMouseLeave?: MouseEventHandler;
    onKeyDown?: KeyboardEventHandler;
    onBlur?: FocusEventHandler;
    onFocus?: FocusEventHandler;
    // a11y props
    ariaExpanded?: boolean;
    ariaHasPopup?: boolean;
    ariaSelected?: boolean;
    ariaLabel?: string;
    ariaControls?: string;
    ariaDescribedBy?: string;
    ariaHidden?: boolean;
    role?: Roles;
    /**
     * DO NOT USE. Because TS+JSX allows "data-*" attributes on all JSX Elements,
     * TS does not error when "data-tn" is added to Link. To prevent accidental use
     * of "data-tn", it has been added as an optional undefined type. Use "dataTn."
     * @summary DO NOT USE. Use "dataTn".
     */
    ['data-tn']?: undefined;
}
interface Anchor extends BaseProps, AnchorExternalProps {
    href: string;
    onClick?: MouseEventHandler;
}
interface Button extends BaseProps, NeverAnchorExternalProps {
    onClick: MouseEventHandler;
}

// discriminated union of two interfaces to ensure one of href OR onClick is
// required and to catch mismatched props (no download w/o href, etc.)

type LinkProps = Button | Anchor;

function isValidHref(href: string | undefined): boolean {
    if (!href) {
        // if href is empty string or undefined, href is not valid
        return false;
    } else if (href === '#') {
        // if href is empty url fragment, href is not valid
        return false;
    } else if (/^\W*?javascript:/.test(href)) {
        // if href contains address-bar-executable function, href is not valid
        //
        // most common is "javascript:void(0)", which returns undefined, doing nothing
        // when it executes. this is used bc it gives definition to href, allowing focus
        return false;
    } else {
        // valid href is string with non-empty value, is not empty fragment and is not
        // a function
        return true;
    }
}

export const Link: FC<LinkProps> = ({
    className,
    buyerLinkType,
    underline,
    dataTn,
    dataAction,
    href,
    target,
    title,
    noFollow = false,
    download,
    children,
    ariaExpanded,
    ariaHasPopup,
    ariaSelected,
    ariaDescribedBy,
    ariaControls,
    ariaLabel,
    ariaHidden,
    linkRef,
    role,
    onClick,
    id,
    tabIndex,
    onKeyDown,
    onMouseEnter,
    onMouseLeave,
    onBlur,
    onFocus,
}) => {
    const isAnchor = isValidHref(href);

    const classes = classnames(
        styles.link,
        underline && styles[underline],
        buyerLinkType && styles[buyerLinkType],
        className
    );
    const intl = useIntl();
    if (isAnchor) {
        let rel: string | undefined = noFollow ? 'nofollow' : '';
        if (target === '_blank') {
            rel = `${rel} noopener noreferrer`;
        }
        rel = rel.trim();
        if (!rel) {
            rel = undefined;
        }

        return (
            // eslint-disable-next-line react/forbid-elements
            <a
                id={id}
                className={classes}
                onClick={onClick}
                onKeyDown={onKeyDown}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                onBlur={onBlur}
                onFocus={onFocus}
                tabIndex={tabIndex}
                role={role}
                aria-expanded={ariaExpanded}
                aria-haspopup={ariaHasPopup}
                aria-selected={ariaSelected}
                aria-describedby={ariaDescribedBy}
                aria-label={ariaLabel}
                aria-controls={ariaControls}
                aria-hidden={ariaHidden}
                data-tn={dataTn}
                data-action={dataAction}
                // anchor props
                rel={rel}
                href={handleLocaleUrl(href, intl?.locale)}
                target={target}
                title={title}
                download={download}
                ref={linkRef as MutableRefObject<HTMLAnchorElement>}
            >
                {children}
            </a>
        );
    }

    return (
        // eslint-disable-next-line react/forbid-elements
        <button
            id={id}
            className={classes}
            onClick={onClick}
            onKeyDown={onKeyDown}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onBlur={onBlur}
            onFocus={onFocus}
            tabIndex={tabIndex}
            role={role}
            aria-expanded={ariaExpanded}
            aria-haspopup={ariaHasPopup}
            aria-selected={ariaSelected}
            aria-describedby={ariaDescribedBy}
            aria-label={ariaLabel}
            aria-controls={ariaControls}
            aria-hidden={ariaHidden}
            data-tn={dataTn}
            data-action={dataAction}
            // button props
            type="button"
            ref={linkRef as MutableRefObject<HTMLButtonElement>}
        >
            {children}
        </button>
    );
};
