import { actionTypes } from '../actions/constants';

import { type SupportedCurrencies } from 'dibs-currency-helpers/exports/currencies';

const defaultState = {
    currency: 'USD',
    showCurrency: false,
    sessionCurrency: undefined,
    localCurrency: undefined,
};

type CurrencyState = {
    currency: SupportedCurrencies;
    showCurrency: boolean;
    sessionCurrency?: SupportedCurrencies;
    localCurrency?: SupportedCurrencies;
};

type CurrencyActionType = {
    type: typeof actionTypes.POPULATE_CURRENCY;
    payload: {
        sessionCurrency?: SupportedCurrencies;
        localCurrency?: SupportedCurrencies;
        currency?: SupportedCurrencies;
    };
};

export function currencyReducer(state = defaultState, action: CurrencyActionType): CurrencyState {
    switch (action.type) {
        case actionTypes.POPULATE_CURRENCY:
            return {
                ...state,
                ...action.payload,
                showCurrency: true,
            };
        default:
            return state;
    }
}
