import { createRoot } from 'react-dom/client';
import { Suspense } from 'react';
import { type Environment, RelayEnvironmentProvider } from 'react-relay';
import serverVars from 'server-vars';

import { ErrorBoundary } from 'dibs-error-tools/ErrorBoundary';
import { IntlProvider } from 'dibs-react-intl';

import { SupportChat } from '../components/SupportChat';

const getBuyerSupportChatClient = ({
    node,
    relayEnv,
}: {
    node: HTMLElement | null;
    relayEnv: Environment;
}): void => {
    if (!node) {
        return;
    }

    const locale = serverVars.get('locale');
    const messages = serverVars.get('messages');
    const supportChatRoot = createRoot(node);
    supportChatRoot.render(
        <ErrorBoundary fallback={() => null}>
            <IntlProvider locale={locale} messages={messages}>
                <RelayEnvironmentProvider environment={relayEnv}>
                    <Suspense fallback={null}>
                        <SupportChat />
                    </Suspense>
                </RelayEnvironmentProvider>
            </IntlProvider>
        </ErrorBoundary>
    );
};

export { getBuyerSupportChatClient };
