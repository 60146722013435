import { type FC } from 'react';

import { useSupportChatConfig } from 'dibs-support-chat/exports/useSupportChatConfig';
import { SupportChatLazy } from 'dibs-support-chat/exports/SupportChatLazy';

const SupportChat: FC = () => {
    const { config, shouldInitChat } = useSupportChatConfig();

    if (!shouldInitChat) {
        return null;
    }

    return <SupportChatLazy {...config} />;
};

export { SupportChat };
