import merge from 'deepmerge';
import { startTrackingEvents } from './trackingStatus';
import gtmDataLayer from './gtmDataLayer';
import { pick, isEmpty } from './utils';
import {
    getInitialInfoFiredState,
    setInitialInfoFiredState,
    getInitialPageviewFiredState,
} from './gtmStatus';
import { getGtmPageInfo } from './gtmPageInfo';

import { type UserAttributes } from './types';

export const gtmUserAttributeKeys = [
    'loginStatus',
    'registrationStatus',
    'userID',
    'guestID',
    'userEmail',
    'userEmailAddress',
    'sha256UserEmail',
    'emailToken',
    'userZip',
    'defaultCurrency',
    'contactDealerStatus',
    'buyerStatus',
    'tradeStatus',
    'customerType',
    'tradeTier',
    'tradeFirmId',
    'profileStatus',
    'tradeRewardStatus',
    'registrationDate',
    'incomeRange',
    'currentAccountType',
    'activePromotion',
    'name',
    'line1',
    'line2',
    'city',
    'state',
    'country',
    'zipCode',
    'isPadDevice',
    'isGdprApplicable',
];

/**
 * pushes userAttributes into the dataLayer (along with the gtmPageInfo)
 * @param trackObj
 */
export function addUserAttributes(trackObj: UserAttributes): void {
    if (!isEmpty(trackObj) && !getInitialInfoFiredState()) {
        // filters out invalid userAttribute keys
        const gtmUserAttributes = pick(trackObj, gtmUserAttributeKeys);
        // need to ignore `merge.all` signature otherwise following TS error is thrown:
        // error TS2345: Argument of type 'object' is not assignable to parameter of type 'Record<string, unknown>'.
        // Index signature is missing in type '{}'.
        // @ts-ignore
        // pushing the initial dataLayerInfo directly to the dataLayer
        gtmDataLayer.push(merge.all([gtmUserAttributes, getGtmPageInfo()]));
        setInitialInfoFiredState(true);

        // if the pageView has not been fired then we want to wait for it to be fired to start tracking events
        if (getInitialPageviewFiredState()) {
            startTrackingEvents();
        }
    }
}
