import serverVars from 'server-vars';
import { type RumGlobal } from '@datadog/browser-rum';

type DatadogViewConfig = { name: string; service?: string; version?: string };
export const stack: DatadogViewConfig[] = [];

export function pushDatadogView(datadog: RumGlobal | null, viewConfig: DatadogViewConfig): void {
    const DD_RUM = serverVars.get('DD_RUM');

    if (!DD_RUM || !datadog) {
        return;
    }

    stack.push(viewConfig);
    datadog.startView(viewConfig);
}

export function popDatadogView(datadog: RumGlobal | null): void {
    const DD_RUM = serverVars.get('DD_RUM');

    if (!DD_RUM || !datadog) {
        return;
    }

    stack.pop();
    const viewConfig = stack[stack.length - 1];

    if (viewConfig) {
        datadog.startView(viewConfig);
    } else {
        // eslint-disable-next-line no-console
        console.warn(
            'popDatadogView could not find previous view config. Ensure datadog views are set using pushDatadogView from initSharedDatadogRum.ts'
        );
    }
}
