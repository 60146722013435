import { type FC } from 'react';
import dibsCss from 'dibs-css';
import classnames from 'classnames';

import styles from './styles/ChatAnimation.scss';

type ChatAnimationProps = {
    className?: string;
    showAnimation?: boolean;
    role?: 'img';
    ariaLabel?: string;
    ariaLabelledby?: string;
};

const ChatAnimation: FC<ChatAnimationProps> = props => {
    return (
        <svg
            className={classnames(dibsCss.dibsIcon, styles.icon, props.className, {
                [styles.showAnimation]: props.showAnimation,
            })}
            data-tn={'chat-animation'}
            role={props.role}
            aria-label={props.ariaLabel}
            aria-labelledby={props.ariaLabelledby}
            viewBox="0 0 27 27"
        >
            <g fill="none" transform="translate(0,1)">
                <path
                    d="M25.4 0H1.6C.7 0 0 .7 0 1.5v16.3c0 .9.7 1.6 1.6 1.6h12l6.5 5.5c.1.1.2.1.4.1h.2c.1-.1.2-.3.2-.5v-5.1h4.5c.9 0 1.6-.7 1.6-1.6V1.5c0-.8-.7-1.5-1.6-1.5zm.6 17.8c0 .4-.3.6-.6.6H20v5.1l-6-5.1H1.6c-.4 0-.6-.2-.6-.6V1.5c0-.3.2-.5.6-.5h23.8c.3 0 .6.2.6.5v16.3z"
                    fill="currentColor"
                />
                <g transform="translate(6,8)">
                    <circle
                        className={styles.firstDot}
                        fill="currentColor"
                        transform="translate(1.5,1.5)"
                        r="1.5"
                    />
                    <circle
                        className={styles.secondDot}
                        fill="currentColor"
                        transform="translate(7.5,1.5)"
                        r="1.5"
                    />
                    <circle
                        className={styles.thirdDot}
                        fill="currentColor"
                        transform="translate(13.5,1.5)"
                        r="1.5"
                    />
                </g>
            </g>
        </svg>
    );
};

export default ChatAnimation;
