import dibsCss from 'dibs-css';
import classnames from 'classnames';
import { type FunctionComponent } from 'react';

type LogoVipProps = {
    className?: string;
};

const LogoVip: FunctionComponent<LogoVipProps> = props => {
    return (
        <svg
            role="img"
            className={classnames(dibsCss.dibsIcon, props.className)}
            data-tn="logo-vip"
            viewBox="0 0 84.67529 39.13288"
            aria-label="1stDibs Private Client"
        >
            <path d="M39.6 16.706h1.415v4.834c0 1.455.707 2.28 1.926 2.28a3.198 3.198 0 002.869-1.926l-.079-.118a2.962 2.962 0 01-1.847.826c-.983 0-1.337-.472-1.337-1.73l-.039-4.166h2.752l.275-1.219h-3.027V13.68l-2.908 2.83zM42.233 4.52a3.801 3.801 0 012.398 1.455v-1.69a4.97 4.97 0 00-2.24-.59 2.33 2.33 0 00-2.556 2.28 2.693 2.693 0 001.415 2.2l1.455.984c.707.471.943.786.943 1.336a1.04 1.04 0 01-1.18 1.022c-.864 0-1.571-.629-2.593-1.73v1.848a4.079 4.079 0 002.358.786 2.656 2.656 0 002.752-2.437 2.744 2.744 0 00-1.573-2.32L42.115 6.8c-.59-.393-.943-.747-.943-1.297a.993.993 0 011.061-.983m-7.94 3.46v12.46c0 2.122-.904 2.712-2.752 3.34h7.862c-1.847-.628-2.752-1.218-2.752-3.34V5.975a22.002 22.002 0 01.158-2.91 12.866 12.866 0 01-5.818 2.123v.354c2.83 0 3.302.747 3.302 2.437M39.128 0h-7.901v.983h7.39C46.87.983 50.29 6.17 50.29 13.56c0 8.53-3.656 12.971-10.927 12.971h-8.019v.983h8.097c10.417 0 14.19-7.075 14.19-13.954C53.632 6.918 49.622 0 39.128 0" />
            <path d="M1.727 33.505H0l.182.115a.658.658 0 01.359.668v3.977a.672.672 0 01-.36.676l-.18.115h1.978l-.18-.116a.672.672 0 01-.358-.675v-1.658h.402c1.154 0 1.9-.624 1.9-1.59 0-.961-.735-1.512-2.016-1.512zm-.286.513h.208c.708 0 1.147.385 1.147 1.007a1.008 1.008 0 01-1.123 1.069H1.44z" />
            <path d="M10.197 38.023a22.2 22.2 0 01-.166-.275 4.146 4.146 0 00-1.052-1.34 1.447 1.447 0 001.227-1.415c0-.89-.823-1.488-2.047-1.488H7.26l-.057.007-.814-.01.186.118a.658.658 0 01.359.668v3.977a.672.672 0 01-.359.676l-.18.115h1.978l-.18-.115a.672.672 0 01-.359-.676V36.57c.343.01.569.258.915.82l1.016 1.636.019.03h1.275v-.15l-.05-.012c-.251-.054-.48-.332-.812-.871zm-.937-3.03c0 .864-.687 1.046-1.263 1.046h-.163v-2.021h.147c.637 0 1.279.301 1.279.975z" />
            <path d="M15.431 33.62l.181-.115h-1.98l.181.115a.658.658 0 01.36.668v3.977a.672.672 0 01-.36.676l-.18.115h1.978l-.18-.115a.672.672 0 01-.358-.676v-3.977a.659.659 0 01.358-.668z" />
            <path d="M21.668 33.638a.502.502 0 01.27.433 1.084 1.084 0 01-.081.397l-1.118 3.017-1.065-2.982a1.521 1.521 0 01-.122-.479.419.419 0 01.248-.387l.2-.116h-1.94l.144.112a1.461 1.461 0 01.49.762l1.752 4.681.015.041h.312l1.806-4.745a1.57 1.57 0 01.516-.735l.195-.116h-1.834z" />
            <path d="M29.004 38.18l-1.752-4.68-.015-.042h-.311l-1.806 4.745a1.569 1.569 0 01-.516.737l-.194.116h1.833l-.213-.117a.501.501 0 01-.27-.434 1.095 1.095 0 01.081-.398l.38-1.028h1.448l.357 1a1.509 1.509 0 01.12.473.419.419 0 01-.247.388l-.2.116h1.94l-.143-.113a1.459 1.459 0 01-.492-.762zm-1.55-1.69h-1.016l.521-1.4z" />
            <path d="M31.238 34.604l.133-.107a1.585 1.585 0 011.123-.441h.34v4.209a.672.672 0 01-.358.676l-.18.115h1.978l-.18-.115a.672.672 0 01-.36-.676v-4.21h.318a1.576 1.576 0 011.122.442l.134.107v-1.099h-4.07z" />
            <path d="M40.306 38.45h-.945v-1.968h1.046a.846.846 0 01.685.207l.106.102v-1.2l-.106.101a.838.838 0 01-.685.2h-1.046v-1.797h.682a1.61 1.61 0 011.153.303l.103.085v-.978H37.92l.182.115a.658.658 0 01.359.668v3.977a.672.672 0 01-.359.676l-.18.115h3.324l.258-1.001-.142.105a1.57 1.57 0 01-1.056.29z" />
            <path d="M50.955 33.419a2.847 2.847 0 100 5.691 3.575 3.575 0 001.741-.41l.034-.017v-1.218l-.112.15a1.971 1.971 0 01-3.593-1.35 1.969 1.969 0 011.93-2.217 2.009 2.009 0 011.663.86l.112.144v-1.214l-.035-.017a3.705 3.705 0 00-1.74-.402z" />
            <path d="M58.038 38.45h-1.1v-4.162a.658.658 0 01.358-.668l.182-.115h-1.981l.182.115a.659.659 0 01.359.668v3.977a.672.672 0 01-.36.676l-.18.115h3.48l.257-1.001-.141.105a1.57 1.57 0 01-1.056.29z" />
            <path d="M63.644 33.62l.181-.115h-1.98l.18.115a.658.658 0 01.36.668v3.977a.672.672 0 01-.36.676l-.18.115h1.98l-.181-.115a.672.672 0 01-.359-.676v-3.977a.659.659 0 01.359-.668z" />
            <path d="M68.903 38.45h-.945v-1.968h1.046a.846.846 0 01.685.207l.106.102v-1.2l-.106.101a.838.838 0 01-.685.2h-1.046v-1.797h.682a1.61 1.61 0 011.153.303l.103.085v-.978h-3.378l.181.115a.658.658 0 01.36.668v3.977a.672.672 0 01-.36.676l-.18.115h3.324l.258-1.001-.142.105a1.57 1.57 0 01-1.056.29z" />
            <path d="M76.38 33.62c.247.157.358.303.358.753v2.631l-2.428-3.473-.019-.026h-1.504l.182.115a.658.658 0 01.359.668v3.9c0 .448-.111.594-.36.753l-.18.115h1.926l-.242-.118c-.304-.149-.43-.286-.43-.75v-3.565l3.204 4.484.019.026h.188v-4.76c0-.45.11-.596.358-.753l.181-.115h-1.794z" />
            <path d="M80.605 33.505v1.099l.132-.107a1.585 1.585 0 011.124-.441h.34v4.209a.672.672 0 01-.358.676l-.18.115h1.978l-.18-.115a.672.672 0 01-.36-.676v-4.21h.318a1.58 1.58 0 011.122.441l.134.107v-1.098z" />
        </svg>
    );
};

export default LogoVip;
