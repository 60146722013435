import { getLang } from 'dibs-intl/exports/languages';
import { LOCALE_US, isLocale } from 'dibs-intl/exports/locales';
import { removeLocaleSegment } from 'dibs-intl/exports/urls';
import serverVars from 'server-vars';
import { mergeAdditionalData } from './utils';
import { startTrackingEvents } from './trackingStatus';
import { getGtmEventQueue } from './gtmEventQueue';
import {
    getInitialPageviewFiredState,
    setInitialPageviewFiredState,
    getInitialInfoFiredState,
} from './gtmStatus';
import { getSessionPageCount } from './sessionCount';
import { eventTypeConstants } from './constants';

import { type TrackingObject } from './types';

/**
 * check for this value at run time instead of setting it once at run time
 * @returns {boolean}
 */
const isGaAllowed = (): boolean => {
    if (serverVars.get('settings.allowGa') === false) {
        return false;
    }
    return true;
};

/**
 * The main page view tracking call
 * @param {object} trackObj                 An object with a url key (can also be undefined in which case
 *                                          GTM will internally grab the url from the page)
 * @param {string} trackObj.url
 * @param {object} [trackObj.additional]    An object with any additional key/value pairs to pass to GTM
 *                                          added to gtmData in mergeAdditionalData(gtmData, trackObj)
 */
export function trackPageview(trackObj: TrackingObject = {}): void {
    // this is necessary for stale logins on checkout
    // so that the page view tracking doesn't fire twice
    if (isGaAllowed()) {
        const gtmEventQueue = getGtmEventQueue();
        const gtmData: TrackingObject = mergeAdditionalData(
            {
                event: eventTypeConstants.EVENT_PAGEVIEW,
                opt_pageURL: trackObj && trackObj.url,
                pageCountViewed: getSessionPageCount(),
            },
            trackObj
        );
        gtmData.opt_pageURL = gtmData.opt_pageURL || undefined;

        if (gtmData.opt_pageURL && /^https?/.test(gtmData.opt_pageURL)) {
            const parts = gtmData.opt_pageURL.split('/');
            // => ["https:", "", "www.1stdibs.com", "path", "segment"];
            // start from the 3rd index
            // safe to add a preceding slash here since there
            // is no empty string in the 3rd index to join with
            let path = '/' + parts.slice(3).join('/'); // => path/segment
            if (!/\/$/.test(path)) {
                // if path does NOT end in /, add it
                path = path + '/';
            }
            gtmData.opt_pageURL = path;
        }

        const localeFromServerVars = serverVars.get('locale');
        const locale = isLocale(localeFromServerVars) ? localeFromServerVars : LOCALE_US;

        gtmData.locale = locale;
        gtmData.language = getLang(locale);

        // If there is intl segment in the url i.e.: /de/furniture/ remove it and leave /furniture/. Analytics will segment page activities in GA by locale and language.
        if (gtmData?.opt_pageURL) {
            gtmData.opt_pageURL = removeLocaleSegment(gtmData.opt_pageURL);
        }

        if (document && !gtmData?.opt_pageURL && locale !== LOCALE_US) {
            gtmData.opt_pageURL = removeLocaleSegment(document.location.pathname);
        }

        // If this is not the initial page view then just push it like a regular event
        if (getInitialPageviewFiredState()) {
            gtmEventQueue.push(gtmData);

            // If it is the initial page view then add it to the beginning of the queue, switch flag
        } else {
            gtmEventQueue.unshift(gtmData);
            setInitialPageviewFiredState(true);

            // if the initial dataLayer info has not been added then we want to wait to start tracking events
            if (getInitialInfoFiredState()) {
                startTrackingEvents();
            }
        }
    }
}
