import { type SupportChatConfig } from './types';

const CHAT_ACTIONS = {
    SET_SHOW_CHAT: 'SET_SHOW_CHAT',
    SET_IS_CHAT_LOADING: 'SET_IS_CHAT_LOADING',
    SET_IS_CHAT_INITIALIZED: 'SET_IS_CHAT_INITIALIZED',
    SET_IS_CHAT_BUTTON_VISIBLE: 'SET_IS_CHAT_BUTTON_VISIBLE',
    SET_IS_CHAT_WINDOW_VISIBLE: 'SET_IS_CHAT_WINDOW_VISIBLE',
    SET_IS_END_CHAT_VISIBLE: 'SET_IS_END_CHAT_VISIBLE',
    SET_IS_FEEDBACK_FORM_VISIBLE: 'SET_IS_FEEDBACK_FORM_VISIBLE',
    SET_IS_FEEDBACK_FORM_SUBMITTED: 'SET_IS_FEEDBACK_FORM_SUBMITTED',
    SET_IS_AGENT_CONNECTED: 'SET_IS_AGENT_CONNECTED',
    SET_IS_MESSAGING_CLIENT_INITIALIZED: 'SET_IS_MESSAGING_CLIENT_INITIALIZED',
    SET_IS_EXISTING_CONVERSATION: 'SET_IS_EXISTING_CONVERSATION',
    SET_IS_NEW_MESSAGING_SESSION: 'SET_IS_NEW_MESSAGING_SESSION',
    SET_MESSAGE: 'SET_MESSAGE',
    SET_IN_FLIGHT_MESSAGE: 'SET_IN_FLIGHT_MESSAGE',
    SET_CAN_SEND_MESSAGE: 'SET_CAN_SEND_MESSAGE',
    SET_HAS_UNREAD_MESSAGE: 'SET_HAS_UNREAD_MESSAGE',
    SET_FILE: 'SET_FILE',
    SET_CONVERSATION_ERROR_MESSAGE: 'SET_CONVERSATION_ERROR_MESSAGE',
    SET_CONVERSATION_MESSAGE_ERROR_MESSAGE: 'SET_CONVERSATION_MESSAGE_ERROR_MESSAGE',
    SET_SEND_MESSAGE_ERROR_MESSAGE: 'SET_SEND_MESSAGE_ERROR_MESSAGE',
    SET_ACTIONS_ERROR_MESSAGE: 'SET_ACTIONS_ERROR_MESSAGE',
    SET_CONVERSATION_STATUS: 'SET_CONVERSATION_STATUS',
    ADD_CONVERSATION_ENTRIES: 'ADD_CONVERSATION_ENTRIES',
    ADD_PREVIOUS_CONVERSATION_ENTRIES: 'ADD_PREVIOUS_CONVERSATION_ENTRIES',
    SET_PRE_CHAT_FIELDS: 'SET_PRE_CHAT_FIELDS',
    SET_AGENT_NAME: 'SET_AGENT_NAME',
    SET_AGENT_WAIT_TIME: 'SET_AGENT_WAIT_TIME',
    SET_TYPING_INDICATOR_ID: 'SET_TYPING_INDICATOR_ID',
    SET_MESSAGING_SESSION_ID: 'SET_MESSAGING_SESSION_ID',
    START_NEW_CHAT: 'START_NEW_CHAT',
    END_CHAT_ON_ERROR: 'END_CHAT_ON_ERROR',
} as const;

const CONVERSATION_STATUSES = {
    NOT_STARTED: 'NOT_STARTED',
    OPEN: 'OPEN',
    CLOSED: 'CLOSED',
} as const;

const CSS_VARIABLES = {
    ANIMATION_DURATION_MS: 400,
    AVATAR_GAP: '9px',
    AVATAR_WIDTH: '27px',
    BORDER_RADIUS: '8px',
    CHAT_MESSAGE_SPACING: '14px',
    CHAT_WINDOW_SPACING: '27px',
    CHAT_WINDOW_WIDTH: '378px',
    TIMING_FUNCTION: 'cubic-bezier(0.68, -0.01, 0.35, 1.02)',
} as const;

const PRE_CHAT_FIELDS = {
    dibsUserId: 'dibsUserId',
    dibsSellerId: 'dibsSellerId',
    itemId: 'itemId',
    messageInitiator: 'messageInitiator',
    orderId: 'orderId',
    pageType: 'pageType',
    topic: 'topic',
    touchpoint: 'touchpoint',
} as const;

const STORAGE_KEYS = {
    SUPPORT_CHAT_JWT: 'SUPPORT_CHAT_JWT',
    SUPPORT_CHAT_ORGANIZATION_ID: 'SUPPORT_CHAT_ORGANIZATION_ID',
    SUPPORT_CHAT_DEPLOYMENT_DEVELOPER_NAME: 'SUPPORT_CHAT_DEPLOYMENT_DEVELOPER_NAME',
    SUPPORT_CHAT_MESSAGING_URL: 'SUPPORT_CHAT_MESSAGING_URL',
    SUPPORT_CHAT_DEPLOYMENT_CONFIGURATION: 'SUPPORT_CHAT_DEPLOYMENT_CONFIGURATION',
    SUPPORT_CHAT_HAS_ACTIVE_CHAT: 'SUPPORT_CHAT_HAS_ACTIVE_CHAT',
    SUPPORT_CHAT_CONFIG: 'SUPPORT_CHAT_CONFIG',
} as const;

const TYPING_INDICATOR_DISPLAY_TIMEOUT = 5000;

const WEB_STORAGE_KEY = 'SUPPORT_CHAT_MESSAGING_WEB_STORAGE';

const SUPPORT_CHAT_CONTAINER_ID = 'dibs-live-support-chat';
const SELLER_CHAT_CONTAINER_ID = 'dibs-seller-chat';

const SUPPORT_CHAT_EVENTS = {
    SUPPORT_CHAT_SET_CONFIG: 'supportchatsetconfig',
    SUPPORT_CHAT_INIT_CHAT: 'supportchatinitchat',
    SUPPORT_CHAT_START_CHAT: 'supportchatstartchat',
    SUPPORT_CHAT_LOADED: 'supportchatloaded',
};

const SUPPORT_CHAT_TOUCH_POINTS: SupportChatConfig['touchPoint'][] = [
    'Buyer_Messaging',
    'Guest_Buyer_Messaging',
    'Seller_Messaging',
];
const SELLER_SUPPORT_CHAT_TOUCHPOINTS: SupportChatConfig['touchPoint'][] = ['Seller_Messaging'];
const DISABLE_AUTH_SUPPORT_CHAT_TOUCH_POINTS: SupportChatConfig['touchPoint'][] = [
    'Guest_Buyer_Messaging',
];

export {
    CHAT_ACTIONS,
    CONVERSATION_STATUSES,
    CSS_VARIABLES,
    PRE_CHAT_FIELDS,
    SELLER_CHAT_CONTAINER_ID,
    SELLER_SUPPORT_CHAT_TOUCHPOINTS,
    STORAGE_KEYS,
    SUPPORT_CHAT_CONTAINER_ID,
    SUPPORT_CHAT_EVENTS,
    SUPPORT_CHAT_TOUCH_POINTS,
    DISABLE_AUTH_SUPPORT_CHAT_TOUCH_POINTS,
    TYPING_INDICATOR_DISPLAY_TIMEOUT,
    WEB_STORAGE_KEY,
};
