import { type FC } from 'react';
import classnames from 'classnames';

import { Spinner } from 'dibs-elements/exports/Spinner';
import styles from './styles/OverlaySpinner.scss';

const OverlaySpinner: FC<{ withDimBackground?: boolean }> = ({ withDimBackground }) => (
    <div
        className={classnames(styles.spinnerWrapper, {
            [styles.withDimBackground]: withDimBackground,
        })}
    >
        <Spinner dark />
    </div>
);

export default OverlaySpinner;
