/**
 * @generated SignedSource<<3478b19fb439a323e2525fc2ae14dc0f>>
 * @relayHash c49f8a27f2927fb4673e70474295efe2
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID ferrum/504.0.0-2025-04-01T10:32:06.638Z/getFeatureFlagsQuery

import { ConcreteRequest } from 'relay-runtime';
export type getFeatureFlagsQuery$variables = Record<PropertyKey, never>;
export type getFeatureFlagsQuery$data = {
  readonly viewer: {
    readonly googleOneTapAutoLogin: boolean | null;
  };
};
export type getFeatureFlagsQuery = {
  response: getFeatureFlagsQuery$data;
  variables: getFeatureFlagsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": "googleOneTapAutoLogin",
  "args": [
    {
      "kind": "Literal",
      "name": "feature",
      "value": "googleOneTapAutoLogin"
    }
  ],
  "kind": "ScalarField",
  "name": "featureFlag",
  "storageKey": "featureFlag(feature:\"googleOneTapAutoLogin\")"
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "getFeatureFlagsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "getFeatureFlagsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "ferrum/504.0.0-2025-04-01T10:32:06.638Z/getFeatureFlagsQuery",
    "metadata": {},
    "name": "getFeatureFlagsQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "49d394bb81e682c7a09f1334108bfe28";

export default node;
