import { hydrateRoot } from 'react-dom/client';
import { type Environment } from 'react-relay';
import { Provider } from 'react-redux';
import { type Store } from 'redux';

import { IntlProvider } from 'dibs-react-intl';
import serverVars from 'server-vars';

import { HeaderFooterServerVarsContextProvider } from '../components/HeaderFooterServerVarsContext/HeaderFooterServerVarsContext';
import { addLoginHandlers, initResetUpdateUserState } from '../utils/loginHandlers';
import { actionCreators } from '../actions';

type HeaderClientArgs = {
    relayEnv: Environment;
    node: Element;
    store: Store;
};

type HeaderClientResponse = {
    store: Store;
    getBuyerHeaderUiState: () => Store;
};

const getBuyerHeaderClient = async ({
    node,
    store,
    relayEnv,
}: HeaderClientArgs): Promise<HeaderClientResponse> => {
    const isDesktop = serverVars.get('settings.isDesktop');
    const isTablet = serverVars.get('settings.isTablet');
    const isMobile = serverVars.get('settings.isMobile');

    let navPromise;

    if (isDesktop) {
        navPromise = import(
            /* webpackChunkName: "responsiveHeader" */ '../components/responsiveHeader'
        );
    } else {
        navPromise = import(/* webpackChunkName: "mobileHeader" */ '../components/mobileHeader');
    }

    const { HeaderRenderer, headerRootQuery, headerInitialVariables } = await navPromise;

    const locale = serverVars.get('locale');
    const messages = serverVars.get('messages');
    const buyerHost = serverVars.get('buyerHost');

    const props = {
        initialVariables: headerInitialVariables,
        environment: relayEnv,
        query: headerRootQuery,
    };

    addLoginHandlers(() => {
        store.dispatch(actionCreators.setUpdateUserState(true));
    });
    initResetUpdateUserState(() => store.dispatch(actionCreators.setUpdateUserState(false)));
    hydrateRoot(
        node,
        <IntlProvider locale={locale} messages={messages}>
            <HeaderFooterServerVarsContextProvider
                buyerHost={buyerHost}
                isMobile={isMobile}
                isTablet={isTablet}
            >
                <Provider store={store}>
                    <HeaderRenderer {...props} />
                </Provider>
            </HeaderFooterServerVarsContextProvider>
        </IntlProvider>,
        { identifierPrefix: 'header' }
    );

    return {
        store,
        getBuyerHeaderUiState: store.getState,
    };
};

export { getBuyerHeaderClient };
