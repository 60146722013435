import { trackWebVitalsGA4Event, promTrackWebVitalsMetric } from 'dibs-tracking';
import { onLCP, onINP, onTTFB, onCLS, type MetricWithAttribution } from 'web-vitals/attribution';

const trackWebVitals = (metric: MetricWithAttribution): void => {
    trackWebVitalsGA4Event(metric);
    promTrackWebVitalsMetric(metric);
};

export function initCustomPerformanceReporting(): void {
    onCLS(trackWebVitals);
    onINP(trackWebVitals);
    onLCP(trackWebVitals);
    onTTFB(trackWebVitals);
}
