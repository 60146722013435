export function waitForOneTrust(timeout: number = 15000): Promise<void> {
    return new Promise((resolve, reject) => {
        const interval = setInterval(() => {
            // @ts-ignore
            if (window.OneTrust) {
                clearInterval(interval);
                resolve();
            }
        }, 50);

        setTimeout(() => {
            clearInterval(interval);
            reject();
        }, timeout);
    });
}
