import {
    type RequestParameters,
    type GraphQLResponseWithData,
    type PayloadError,
} from 'relay-runtime';

export class DibsRelayNetworkError extends Error {
    public traceGuid?: string;
    public method?: string;
    public callRequestGuid?: string;
    public url?: string;
    public status?: number;
    public duration?: number;
    public source?: {
        errors?: PayloadError[] | null;
    } | null;
}

export function createNetworkError(
    operation: RequestParameters,
    json: GraphQLResponseWithData,
    responseHeaders?: Headers,
    requestInfo?: {
        method: string;
        url: string;
        callRequestGuid: string;
        status?: number;
        duration?: number;
    }
): DibsRelayNetworkError {
    const error = new DibsRelayNetworkError('DibsRelayNetworkLayer');
    const errorsMessage = json.errors
        ? json.errors.map(({ message }) => message).join('\n')
        : '(No errors)';

    error.message = `No data returned for operation '${operation.name}' got error(s):\n${errorsMessage}\n\nSee the error 'source' property for more information.`;

    const traceGuid = responseHeaders?.get('x-dibs-trace-guid');
    if (traceGuid) {
        error.traceGuid = traceGuid;
    }

    if (requestInfo) {
        error.method = requestInfo.method;
        error.url = requestInfo.url;
        error.callRequestGuid = requestInfo.callRequestGuid;
        error.status = requestInfo.status;
        error.duration = requestInfo.duration;
    }

    error.source = {
        errors: json.errors,
    };

    return error;
}
