import { graphql, commitMutation, type Environment } from 'react-relay';
import { executeCaptcha } from 'dibs-recaptcha/exports/recaptcha';
import {
    type emailLoginLinkSendMutation as emailLoginLinkSendMutationType,
    type EmailLoginLinkSendInput,
    type emailLoginLinkSendMutation$data,
} from './__generated__/emailLoginLinkSendMutation.graphql';

const mutation = graphql`
    mutation emailLoginLinkSendMutation($input: EmailLoginLinkSendInput!) {
        emailLoginLinkSend(input: $input) {
            clientMutationId
        }
    }
`;

export async function emailLoginLinkSendMutation(
    environment: Environment,
    input: EmailLoginLinkSendInput
): Promise<emailLoginLinkSendMutation$data> {
    const captchaResults = await executeCaptcha({
        action: 'loginLink',
        featureFlag: 'loginLinkCaptcha',
        relayEnvironment: environment,
    });

    return new Promise((resolve, reject) =>
        commitMutation<emailLoginLinkSendMutationType>(environment, {
            mutation,
            variables: { input: { ...input, captchaToken: captchaResults?.captchaToken } },
            onCompleted: resolve,
            onError: reject,
        })
    );
}
