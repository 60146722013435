import serverVars from 'server-vars';
import { localStorage } from 'dibs-browser-storage';
import { isMasquerading } from 'dibs-cookie-jar';
import { getMeasurementUnit } from 'dibs-intl/exports/units';
import {
    getSourceAttribution,
    clearSourceAttribution,
} from 'dibs-visit-tracking/exports/sourceAttribution';
import { VISITS_PER_SESSION_LOGIN_PROMPT } from 'dibs-visit-tracking/exports/visitCountConstants';

import { getCurrencyPreference } from '../../utils/preferredCurrency';
import { getGuestId } from '../../utils/guestIdStorage';
import { userProfileMutation } from '../mutations/userProfileMutation';
import { sourceAttributionMutation } from './sourceAttributionMutation';
import { USER_EMAIL, USER_EMAIL_TOKEN, GUEST_USER, VISIT_COUNT } from './userStorageConstants';

import { type AuthResponseType } from './authTypes';

export const setUserEmailToStorage = (email: string): void => {
    localStorage.setItem(USER_EMAIL, email);
};

// Do not write client-side cookies for long-term storage!! see https://1stdibs.atlassian.net/browse/ENGAGE-979
// submits guestId to sourceAttribution call and adds user data to localStorage
export const setTokens = (payload: AuthResponseType): void => {
    const { environment, user, emailToken, userId, userToken } = payload || {};

    const { profile = {} } = user || {};
    const guestUserId = getGuestId();

    if (guestUserId && !isMasquerading(document.cookie)) {
        sourceAttributionMutation(environment, {
            guestUserId,
            type: GUEST_USER,
            userId,
            userToken,
            emailToken,
            pageVisits: getSourceAttribution(),
        });
    }
    clearSourceAttribution();

    if (profile.email) {
        setUserEmailToStorage(profile.email);
    }
    if (emailToken) {
        localStorage.setItem(USER_EMAIL_TOKEN, emailToken);
    }
};

export const resetVisitCount = (): void => {
    const visitCount =
        (localStorage.getItem(VISIT_COUNT) as {
            regPromptLimit: number;
            totalVisitCount: number;
        }) || {};
    const { regPromptLimit = VISITS_PER_SESSION_LOGIN_PROMPT, totalVisitCount = 0 } = visitCount;
    localStorage.setItem(VISIT_COUNT, {
        totalVisitCount,
        sinceRegPrompt: 0,
        regPromptLimit,
    });
};

export const updateProfile = (payload: AuthResponseType): Promise<AuthResponseType> => {
    const { environment, userId } = payload;
    const locale = serverVars.get('locale');
    const currency = getCurrencyPreference();
    const measurementUnit = getMeasurementUnit(locale);
    return new Promise(resolve => {
        userProfileMutation(environment, {
            currency,
            measurementUnit,
            preferredLocale: locale,
            userServiceId: userId,
            onCompleted: () => {
                resolve(payload);
            },
            // don't reject - if this mutation fails do not show the user an error
            onError: () => resolve(payload),
        });
    });
};
