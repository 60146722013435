import { ClientCookieManager } from 'dibs-consented-storage';

import { PASSWORD_TOKEN, USER_EMAIL_TOKEN } from './userStorageConstants';

export const getUserEmailToken = (): string | undefined =>
    ClientCookieManager.get(USER_EMAIL_TOKEN);

export const getPasswordToken = (): string | undefined => ClientCookieManager.get(PASSWORD_TOKEN);

export const removePasswordToken = (): void => {
    ClientCookieManager.remove(PASSWORD_TOKEN);
};
