import { type FC } from 'react';
import classnames from 'classnames';
import Logo2020 from 'dibs-icons/exports/legacy/Logo2020';
import Logo2020Trade from 'dibs-icons/exports/legacy/Logo2020Trade';
import { LocalizedLogoVipIcon } from 'dibs-elements/exports/LocalizedLogoVipIcon';
import styles from './styles/MobileLogo.scss';

const DARK = 'dark';
const LIGHT = 'light';
const PITCH_BLACK = 'pitchBlack';

export const LOGO_TYPE_CONSUMER = 'logo2020';
export const LOGO_TYPE_TRADE = 'logo2020-trade';
export const LOGO_TYPE_VIP = 'logo-vip';

export type MobileLogoProps = {
    isUserTypeTrade?: boolean;
    isUserTypeVip?: boolean;
    user?: {
        isVerifiedTrade?: boolean;
        isVip?: boolean;
    };
    fillColor?: typeof DARK | typeof LIGHT | typeof PITCH_BLACK;
};

const MobileLogo: FC<MobileLogoProps> = ({
    isUserTypeTrade,
    isUserTypeVip,
    user,
    fillColor = LIGHT,
}) => {
    const displayTrade = isUserTypeTrade || user?.isVerifiedTrade;
    const displayPrivateClient = isUserTypeVip || user?.isVip;
    const isDark = fillColor === DARK;
    const isBlack = fillColor === PITCH_BLACK;

    let LogoComponent = Logo2020;
    if (displayTrade) {
        LogoComponent = Logo2020Trade;
    } else if (displayPrivateClient) {
        LogoComponent = LocalizedLogoVipIcon;
    }

    const logoClassName = classnames(styles.logo, {
        [styles.dark]: isDark,
        [styles.navPrivateClient]: displayPrivateClient,
        [styles.black]: isBlack,
    });

    return (
        <div
            className={classnames(styles.container, { [styles.dark]: isDark })}
            data-tn="logo-container"
        >
            <LogoComponent className={logoClassName} />
        </div>
    );
};

export default MobileLogo;
