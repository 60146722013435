import { type FunctionComponent } from 'react';
import Logo2020 from './Logo2020';

type Logo2020TradeProps = {
    className?: string;
};

const Logo2020Trade: FunctionComponent<Logo2020TradeProps> = props => {
    return <Logo2020 className={props.className} isTrade />;
};

export default Logo2020Trade;
