import {
    fetchQuery_DEPRECATED as fetchQuery,
    graphql,
    type Environment,
    type GraphQLTaggedNode,
} from 'relay-runtime';

import { type authorizeTokenQuery } from './__generated__/authorizeTokenQuery.graphql';

const authQuery: GraphQLTaggedNode = graphql`
    query authorizeTokenQuery($cookieDomain: String = "") {
        viewer {
            isUserTokenValid(cookieDomain: $cookieDomain, validateUserTypeCookie: true)
        }
    }
`;

function isNumeric(str: string): boolean {
    const num = parseFloat(str);
    return !isNaN(num) && isFinite(num);
}

/**
 * given a token and a relay environment, look up the userId associated with that token
 * if the lookup explicitly 401s or 404s (thus returning false from GraphQL), then reject the token as invalid
 * otherwise in the case of a 200, 500, GraphQL failure, anything besides a 401 or 404, default to true
 * @param {*} token token to validate (can be user token or email token)
 * @param {*} relayEnv relay environment in which to execute the query
 */
export function authorizeToken(
    token: string = '',
    relayEnv: Environment,
    cookieDomain?: string
): Promise<boolean> {
    const id = token.split('_')[0];
    // if the userId is not a number, we can automatically reject it as invalid
    if (!isNumeric(id)) {
        return Promise.resolve(false);
    }
    return fetchQuery<authorizeTokenQuery>(relayEnv, authQuery, { cookieDomain })
        .then(data => {
            const valid = data?.viewer?.isUserTokenValid;
            // really try hard not to return bad data
            if (valid === true || valid === false) {
                return valid;
            }
            return true;
        })
        .catch(() => true); // don't want to log people out if graphql fails
}
