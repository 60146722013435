import { triggerConstants } from './constants';
import { type TriggerValues } from './types';

export const getBidTrigger = (hasBid: boolean): TriggerValues => {
    if (hasBid) {
        return triggerConstants.TRIGGER_BID_INCREASE;
    }

    return triggerConstants.TRIGGER_BID_CLICK;
};
