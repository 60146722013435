import { useEffect, useState } from 'react';

import { getSupportChatConfig, getHasActiveChat } from '../services/dataProvider';
import { SUPPORT_CHAT_EVENTS } from '../constants';

import { type SupportChatConfig } from '../types';

function useSupportChatConfig():
    | { config: SupportChatConfig; shouldInitChat: true }
    | { config: SupportChatConfig | null; shouldInitChat: false } {
    const [config, setConfig] = useState<SupportChatConfig | null>(getSupportChatConfig());
    const [shouldInitChat, setShouldInitChat] = useState(getHasActiveChat() === 'yes' && !!config);

    useEffect(() => {
        function initChatListener(event: Event): void {
            if (event instanceof CustomEvent) {
                setShouldInitChat(event.detail);
            }
        }

        function setConfigListener(event: Event): void {
            if (event instanceof CustomEvent) {
                setConfig(event.detail);
            }
        }

        window.addEventListener(SUPPORT_CHAT_EVENTS.SUPPORT_CHAT_SET_CONFIG, setConfigListener);
        window.addEventListener(SUPPORT_CHAT_EVENTS.SUPPORT_CHAT_INIT_CHAT, initChatListener);

        return () => {
            window.removeEventListener(
                SUPPORT_CHAT_EVENTS.SUPPORT_CHAT_SET_CONFIG,
                setConfigListener
            );
            window.removeEventListener(
                SUPPORT_CHAT_EVENTS.SUPPORT_CHAT_INIT_CHAT,
                initChatListener
            );
        };
    }, []);

    return !!config && shouldInitChat
        ? { config, shouldInitChat }
        : { config, shouldInitChat: false };
}

export { useSupportChatConfig };
