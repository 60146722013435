import { type FC, type ReactNode } from 'react';
import { ReactRelayContext } from 'react-relay/legacy';
import {
    type RelayContext,
    type ReaderFragment,
    type Snapshot,
    type SingularReaderSelector,
} from 'relay-runtime';

// TODO: make this a hook so consumers do not need to pass context

import { getSelector } from 'relay-runtime'; // To fix mask issue. See usage below

type CommonJSReaderFragment = {
    default: ReaderFragment;
};
type FunctionFragment = () => ReaderFragment;

type UnmaskFragmentData = {
    relayContext: RelayContext;
    relayData: unknown | unknown[]; // taken from `getSelector` argument type
    fragment: ReaderFragment | FunctionFragment | CommonJSReaderFragment; // taken from `getSelector` argument type
};

export const unmaskFragmentData = ({
    relayContext,
    relayData,
    fragment,
}: UnmaskFragmentData): Snapshot['data'] => {
    fragment = typeof fragment === 'function' ? fragment() : fragment;
    // using the relay-compiler-language-typescript generates
    // graphql files with default exports, FlowGenerator generates
    // module.export instead
    let fragmentToUse: ReaderFragment;
    if (fragment && (fragment as CommonJSReaderFragment).default) {
        fragmentToUse = (fragment as CommonJSReaderFragment).default;
    } else {
        fragmentToUse = fragment as ReaderFragment;
    }
    const selector = getSelector(fragmentToUse, relayData) as SingularReaderSelector;
    return relayContext.environment.lookup(selector).data;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function injectRelayContext(Component: FC<any>) {
    // used props: $TSFixMe instead of ts-ignore since we want to type the return type
    return function InjectRelayComponent(props: $TSFixMe): ReactNode {
        return (
            <ReactRelayContext.Consumer>
                {relayContext => <Component {...props} relayContext={relayContext} />}
            </ReactRelayContext.Consumer>
        );
    };
}
