import { actionTypes } from './constants';
import { getSessionCurrency, getLocalCurrency } from '../utils/preferredCurrency';
import { type SupportedCurrencies } from 'dibs-currency-helpers/exports/currencies';

const headerActionCreators = {
    setUpdateUserState: (
        updateUserState: boolean
    ): { type: typeof actionTypes.SET_UPDATE_USER_STATE; updateUserState: boolean } => {
        return { type: actionTypes.SET_UPDATE_USER_STATE, updateUserState };
    },
    setUserTypeTrade: (
        isUserTypeTrade: boolean
    ): { type: typeof actionTypes.SET_USER_TYPE_TRADE; isUserTypeTrade: boolean } => {
        return { type: actionTypes.SET_USER_TYPE_TRADE, isUserTypeTrade };
    },
    setUserTypeVip: (
        isUserTypeVip: boolean
    ): { type: typeof actionTypes.SET_USER_TYPE_VIP; isUserTypeVip: boolean } => {
        return { type: actionTypes.SET_USER_TYPE_VIP, isUserTypeVip };
    },
    setIsEmailOnly: (
        isEmailOnly: boolean
    ): { type: typeof actionTypes.SET_IS_EMAIL_ONLY; isEmailOnly: boolean } => {
        return { type: actionTypes.SET_IS_EMAIL_ONLY, isEmailOnly };
    },
    setInitialTrackingFired: (): { type: typeof actionTypes.INITIAL_TRACKING_FIRED } => {
        return { type: actionTypes.INITIAL_TRACKING_FIRED };
    },
    resetTrackingFired: (): { type: typeof actionTypes.RESET_TRACKING_FIRED } => {
        return { type: actionTypes.RESET_TRACKING_FIRED };
    },
    toggleMobileMenu: (
        isMobileMenuOpen: boolean
    ): { type: typeof actionTypes.TOGGLE_MOBILE_MENU; isMobileMenuOpen: boolean } => {
        return { type: actionTypes.TOGGLE_MOBILE_MENU, isMobileMenuOpen };
    },
    setAddCartItemMutationCompleted: (): {
        type: typeof actionTypes.SET_ADD_CART_ITEM_MUTATION_COMPLETED;
    } => {
        return { type: actionTypes.SET_ADD_CART_ITEM_MUTATION_COMPLETED };
    },
    setActiveLiveChatVisibility: (
        isActiveLiveChatVisible: boolean
    ): {
        type: typeof actionTypes.SET_ACTIVE_LIVE_CHAT_VISIBILITY;
        isActiveLiveChatVisible: boolean;
    } => {
        return { type: actionTypes.SET_ACTIVE_LIVE_CHAT_VISIBILITY, isActiveLiveChatVisible };
    },
};

const currencyActionCreators = {
    populateCurrency: (
        currency: SupportedCurrencies
    ): {
        type: typeof actionTypes.POPULATE_CURRENCY;
        payload: {
            sessionCurrency?: SupportedCurrencies;
            localCurrency?: SupportedCurrencies;
            currency: SupportedCurrencies;
        };
    } => {
        const sessionCurrency = getSessionCurrency();
        const localCurrency = getLocalCurrency();
        return {
            type: actionTypes.POPULATE_CURRENCY,
            payload: { sessionCurrency, localCurrency, currency },
        };
    },
};

const drawerActionCreators = {
    setDrawerState: (
        isDrawerOpen: boolean
    ): { type: typeof actionTypes.SET_DRAWER_STATE; payload: { isDrawerOpen: boolean } } => {
        return { type: actionTypes.SET_DRAWER_STATE, payload: { isDrawerOpen } };
    },
};

const actionCreators = {
    ...headerActionCreators,
    ...currencyActionCreators,
    ...drawerActionCreators,
};

export { actionCreators };
export default { actionCreators };
