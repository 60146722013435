import { mergeAdditionalData, isEmpty } from './utils';
import { getGtmEventQueue } from './gtmEventQueue';
import { eventTypeConstants } from './constants';

import { type TrackingObject } from './types';
/**
 * Form Error tracking using Google Tag Manager
 * @param trackObj
 */
export function trackFormError(trackObj: TrackingObject): void {
    if (!isEmpty(trackObj)) {
        const gtmEventQueue = getGtmEventQueue();
        trackObj.value = typeof trackObj.value !== 'undefined' ? trackObj.value : 0;
        trackObj.noninteraction =
            typeof trackObj.noninteraction !== 'undefined' ? trackObj.noninteraction : 1;

        const gtmData: TrackingObject = mergeAdditionalData(
            {
                event: eventTypeConstants.EVENT_FORM_ERROR,
                category: 'form error',
                eventCategory: 'form error',
                action: trackObj.action || 'no action',
                eventAction: trackObj.action || 'no action',
                label: trackObj.label || 'none',
                eventLabel: trackObj.label || 'none',
                value: trackObj.value,
                noninteraction: trackObj.noninteraction,
            },
            trackObj
        );

        gtmEventQueue.push(gtmData);
    }
}
