/**
 * @generated SignedSource<<97a1f4ee583f4b6d0ec3d16bb8e066f6>>
 * @relayHash 0446928955970b59719768048eafdff2
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID ferrum/504.0.0-2025-04-01T10:32:06.638Z/sourceAttributionMutation

import { ConcreteRequest } from 'relay-runtime';
export type SourceAttributionInput = {
  clientMutationId?: string | null;
  emailToken?: string | null;
  guestUserId?: string | null;
  landingPage?: string | null;
  pageVisits?: ReadonlyArray<pageVisits | null> | null;
  type: string;
  userId: string;
  userToken?: string | null;
  visitCount?: number | null;
};
export type pageVisits = {
  date?: string | null;
  type?: string | null;
  utmParams?: utmParams | null;
};
export type utmParams = {
  landingPage?: string | null;
  visitCount?: number | null;
};
export type sourceAttributionMutation$variables = {
  input: SourceAttributionInput;
};
export type sourceAttributionMutation$data = {
  readonly sourceAttribution: {
    readonly message: string | null;
  } | null;
};
export type sourceAttributionMutation = {
  response: sourceAttributionMutation$data;
  variables: sourceAttributionMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "SourceAttributionPayload",
    "kind": "LinkedField",
    "name": "sourceAttribution",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "message",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "sourceAttributionMutation",
    "selections": (v1/*: any*/),
    "type": "Mutations",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "sourceAttributionMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "ferrum/504.0.0-2025-04-01T10:32:06.638Z/sourceAttributionMutation",
    "metadata": {},
    "name": "sourceAttributionMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "0776fc099f129d46b7995c62aa8613ee";

export default node;
