import { useEffect, type RefObject } from 'react';

type RequireMutationObserverInitKeys<T extends MutationObserverInit, K extends keyof T> = Required<
    Pick<T, K>
> &
    Omit<T, K>;

/**
 * At a minimum, one of `childList`, `attributes`, and/or `characterData` must be true
 * https://developer.mozilla.org/en-US/docs/Web/API/MutationObserver/observe#options
 */
type MutationObserverOptions =
    | RequireMutationObserverInitKeys<MutationObserverInit, 'attributes'>
    | RequireMutationObserverInitKeys<MutationObserverInit, 'characterData'>
    | RequireMutationObserverInitKeys<MutationObserverInit, 'childList'>;

/**
 * https://developer.mozilla.org/en-US/docs/Web/API/MutationObserver
 */
export const useMutationObserver = <T extends HTMLElement>({
    ref,
    callback,
    options,
}: {
    ref: RefObject<T>;
    callback: MutationCallback;
    options: MutationObserverOptions;
}): void => {
    useEffect(() => {
        if (ref.current) {
            const mutationObserver = new MutationObserver(callback);
            mutationObserver.observe(ref.current, options);

            return () => mutationObserver.disconnect();
        }
        return () => {};
    }, [ref, callback, options]);
};
