import serverVars from 'server-vars';
import { getTrafficType as getTrafficTypeCookieValue } from 'dibs-cookie-jar';
import { type Cookie } from 'dibs-cookie-jar/exports/types';

const BT_TRAFFIC = 'bt';

function getTrafficType(cookie: Cookie, url: URL | null): string | null {
    const bt = serverVars.get('btdtctd');

    if (bt === 'true') {
        return BT_TRAFFIC;
    }

    const trafficTypeCookieValue = getTrafficTypeCookieValue(cookie, url);

    if (trafficTypeCookieValue) {
        return trafficTypeCookieValue;
    }

    return null;
}

export { getTrafficType };
