import { trackEvent, eventNameConstants } from 'dibs-tracking';
import {
    trackMessageSent as trackSupportChatMessageSent,
    trackEndChat as trackSupportChatEndChat,
} from 'dibs-support-chat/exports/tracking';

export const CATEGORY = 'contact 1stdibs';

export const LIVE_CHAT_ACTION_MAP = {
    invitationDisplayed: 'live chat invitation displayed',
    offline: 'live chat offline',
    messageSubmitted: 'submitted',
} as const;
type LiveChatEventAction = (typeof LIVE_CHAT_ACTION_MAP)[keyof typeof LIVE_CHAT_ACTION_MAP];

type Placement = 'checkout' | 'pdp';

type SetUpArgs = {
    placement: Placement;
    defaultLabel: string;
    defaultStepType: string;
};

type Args = {
    label?: string;
    stepType?: string;
    action: LiveChatEventAction;
    isInteractiveEvent: boolean;
};

export const liveChatTracking =
    ({ placement, defaultLabel, defaultStepType }: SetUpArgs) =>
    ({ label, action, stepType, isInteractiveEvent }: Args): void => {
        trackEvent({
            category: CATEGORY,
            label: label || defaultLabel,
            interaction_type: `${placement} - live chat`,
            eventName: eventNameConstants.EVENT_CONTACT_1STDIBS,
            action,
            step_interaction_name: action,
            isInteractiveEvent,
            step_type: stepType || defaultStepType,
        });
    };

let isFirstMessage = true;
export function trackMessageSent(): void {
    trackSupportChatMessageSent(isFirstMessage);
    isFirstMessage = false;
}
export function trackEndChat(): void {
    trackSupportChatEndChat();
    isFirstMessage = true;
}
