import { SELLER_CHAT_CONTAINER_ID } from '../constants';
import styles from '../styles/hidePdpSellerChat.module.css';

/**
 * When `embedded_svc` or `dibs-support-chat` pre-chat button is rendered on PDP,
 * seller chat (`dibs-buyer-chat`) should not be visible.
 */
export const hidePdpSellerChat = ({ hide }: { hide: boolean }): void => {
    const sellerChat = document.getElementById(SELLER_CHAT_CONTAINER_ID);

    if (sellerChat) {
        if (hide) {
            sellerChat.classList.add(styles.hidden);
        } else {
            sellerChat.classList.remove(styles.hidden);
        }
    }
};
