import { type RumGlobal } from '@datadog/browser-rum';

type DatadogMethod = keyof RumGlobal;
type DatadogArgs = [never, $TSFixMe];

class Datadog {
    run: RumGlobal;
    importPromise: Promise<RumGlobal | null> | null;

    constructor() {
        this.importPromise = null;

        this.run = new Proxy({} as RumGlobal, {
            get:
                (target, prop: DatadogMethod) =>
                async (...args: DatadogArgs) => {
                    const rumGlobal = await (this.importPromise || this.load());
                    if (!rumGlobal) {
                        // there was an issue loading the script, silently fail
                        return () => {};
                    }
                    const method = rumGlobal[prop];
                    return typeof method !== 'function' ? method : method(...args);
                },
        });
    }
    async load(): Promise<RumGlobal | null> {
        this.importPromise =
            this.importPromise ||
            import(/* webpackChunkName: "dibs-datadog" */ '@datadog/browser-rum')
                .then(pkg => pkg.datadogRum)
                .catch(() => null);
        return this.importPromise;
    }
}

export const datadog = new Datadog();
