/**
 * @generated SignedSource<<0160dfc92d9d973763479d2528183a7e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type userTracking_viewer$data = {
  readonly gdprDisplayInfo?: {
    readonly isGdprApplicable: boolean | null;
  } | null;
  readonly transactionsCounter?: {
    readonly confirmedTransactions: number | null;
    readonly submittedTransactions: number | null;
  } | null;
  readonly " $fragmentType": "userTracking_viewer";
};
export type userTracking_viewer$key = {
  readonly " $data"?: userTracking_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"userTracking_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "fetchTrackingGdprInfo"
    },
    {
      "kind": "RootArgument",
      "name": "hasUserId"
    },
    {
      "kind": "RootArgument",
      "name": "userCountryCode"
    },
    {
      "kind": "RootArgument",
      "name": "userId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "userTracking_viewer",
  "selections": [
    {
      "condition": "hasUserId",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "buyerId",
              "variableName": "userId"
            }
          ],
          "concreteType": "TransactionsCounter",
          "kind": "LinkedField",
          "name": "transactionsCounter",
          "plural": false,
          "selections": [
            {
              "alias": "submittedTransactions",
              "args": null,
              "kind": "ScalarField",
              "name": "submitted",
              "storageKey": null
            },
            {
              "alias": "confirmedTransactions",
              "args": null,
              "kind": "ScalarField",
              "name": "confirmed",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ]
    },
    {
      "condition": "fetchTrackingGdprInfo",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "gdprDisplayInfo",
          "kind": "LinkedField",
          "name": "gdprDisplayInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": [
                {
                  "kind": "Variable",
                  "name": "countryCode",
                  "variableName": "userCountryCode"
                }
              ],
              "kind": "ScalarField",
              "name": "isGdprApplicable",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ]
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "83e04ca1b298ca255b382e96fdf8a102";

export default node;
