import merge from 'deepmerge';

import { type TrackingObject } from './types';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
export function isEmpty(value: any): boolean {
    return value ? !Object.keys(value).length : true;
}

export function pick<T extends ObjectType>(data: T, dataKeys: string[]): T {
    return Object.keys(data).reduce((acc, key: keyof T) => {
        if (typeof key !== 'number' && dataKeys.indexOf(key as string) !== -1) {
            acc[key] = data[key];
        }
        return acc;
    }, {} as T);
}

export function omit<T extends ObjectType>(data: T, dataKeys: string[]): T {
    return Object.keys(data).reduce((acc, key: keyof T) => {
        if (typeof key !== 'number' && dataKeys.indexOf(key as string) === -1) {
            acc[key] = data[key];
        }
        return acc;
    }, {} as T);
}

/**
 * this helper function is used in tracking.trackPageView, tracking.trackSocial, and tracking.trackEvent
 * to merge additional data into the trackObject that is pushed to the dataLayer
 * @param gtmData
 * @param trackObj
 */
export function mergeAdditionalData(
    gtmData: TrackingObject,
    trackObj: TrackingObject
): TrackingObject {
    const additionalData: TrackingObject['additional'] =
        trackObj && trackObj.additional ? trackObj.additional : {};
    return merge(gtmData, additionalData);
}
