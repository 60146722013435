import { type FC, lazy } from 'react';

import { type SupportChatConfig } from './types';

const SupportChatLazyRenderer = lazy(() =>
    import(/* webpackChunkName: "SupportChatRenderer" */ './SupportChatRenderer').then(mod => ({
        default: mod.SupportChatRenderer,
    }))
);

type Props = SupportChatConfig;

const SupportChatLazy: FC<Props> = ({ ...config }) => <SupportChatLazyRenderer {...config} />;

export { SupportChatLazy };
