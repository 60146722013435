import { type ReactElement } from 'react';
import { FormattedMessage } from 'dibs-react-intl';

export const errorMessage: ReactElement = (
    <FormattedMessage
        id="dbl.sharedModalCompents.errorMessage"
        defaultMessage="An error occurred while processing your request. Please try again later."
    />
);

export const loadingMessage: ReactElement = (
    <FormattedMessage id="dbl.sharedModalCompents.loadingMessage" defaultMessage="Loading..." />
);

export const invalidCharacterErrorMessage: ReactElement = (
    <FormattedMessage
        id="dbl.sharedModalCompents.invalidCharacterErrorMessage"
        defaultMessage="Your message could not be sent because it contains unsupported characters. Please remove any special characters and try again."
    />
);
