import { getBuyerId } from 'dibs-cookie-jar';
import { getTrafficType } from 'dibs-tracking';
import { type RumGlobal } from '@datadog/browser-rum';

export const setDatadogUserValues = (datadog: RumGlobal): void => {
    const userId = getBuyerId(document.cookie);
    if (userId) {
        datadog.setUser({ id: userId });
    }
    const trafficType = getTrafficType(document.cookie, new URL(window.location.href));
    if (trafficType) {
        datadog.setGlobalContextProperty('trafficType', trafficType);
    }
};
