import { type ReactNode } from 'react';
import copy from './authCopy';
import * as flows from './authFlowConstants';

function warnOnInvalidFlow(payload: { flow?: typeof flows }): void {
    // @ts-ignore Type 'typeof import("/ferrum/packages/dibs-buyer-layout/src/authModal/authentication/authFlowConstants")' cannot be used as an index type.
    if (payload.flow && !flows[payload.flow]) {
        // eslint-disable-next-line no-console
        console.warn(
            'invalid authentication flow name. make sure the flow is in authFlowConstants'
        );
    }
}

type AuthActionReturnType<T> = { type: T; payload: $TSFixMe };

// modal actions
export const LAUNCH_LOGIN_MODAL = 'LAUNCH_LOGIN_MODAL';
export function launchLoginModal(payload = {}): AuthActionReturnType<typeof LAUNCH_LOGIN_MODAL> {
    warnOnInvalidFlow(payload);
    return { type: LAUNCH_LOGIN_MODAL, payload };
}

export const LAUNCH_REGISTRATION_MODAL = 'LAUNCH_REGISTRATION_MODAL';
export function launchRegistrationModal(
    payload = {}
): AuthActionReturnType<typeof LAUNCH_REGISTRATION_MODAL> {
    warnOnInvalidFlow(payload);
    return { type: LAUNCH_REGISTRATION_MODAL, payload };
}

export const LAUNCH_REQUEST_PASSWORD_EMAIL = 'LAUNCH_REQUEST_PASSWORD_EMAIL';
export function launchRequestPasswordEmailModal(
    payload: $TSFixMe
): AuthActionReturnType<typeof LAUNCH_REQUEST_PASSWORD_EMAIL> {
    return { type: LAUNCH_REQUEST_PASSWORD_EMAIL, payload };
}

export const LAUNCH_RESET_PASSWORD = 'LAUNCH_RESET_PASSWORD';
export function launchResetPasswordModal(
    payload: $TSFixMe
): AuthActionReturnType<typeof LAUNCH_RESET_PASSWORD> {
    return { type: LAUNCH_RESET_PASSWORD, payload };
}

export const LAUNCH_UPDATE_PASSWORD = 'LAUNCH_UPDATE_PASSWORD';
export function launchUpdatePasswordModal(
    payload: $TSFixMe
): AuthActionReturnType<typeof LAUNCH_UPDATE_PASSWORD> {
    return { type: LAUNCH_UPDATE_PASSWORD, payload };
}

export const LAUNCH_SOCIAL_ACCOUNT_LINKED = 'LAUNCH_SOCIAL_ACCOUNT_LINKED';
export function launchSocialAccountLinkedModal(): { type: typeof LAUNCH_SOCIAL_ACCOUNT_LINKED } {
    return { type: LAUNCH_SOCIAL_ACCOUNT_LINKED };
}

export const LAUNCH_SSO = 'LAUNCH_SSO';
export function launchSsoModal(payload: $TSFixMe): AuthActionReturnType<typeof LAUNCH_SSO> {
    return { type: LAUNCH_SSO, payload };
}

export const NEXT_STEP = 'NEXT_STEP';
export function nextStep(payload: $TSFixMe): AuthActionReturnType<typeof NEXT_STEP> {
    return { type: NEXT_STEP, payload };
}

export const CLOSE_MODAL = 'CLOSE_MODAL';
export function closeModal(payload: $TSFixMe): AuthActionReturnType<typeof CLOSE_MODAL> {
    return { type: CLOSE_MODAL, payload };
}

export const SHOULD_UPDATE_TOKEN = 'SHOULD_UPDATE_TOKEN';

export const CLEAR_SERVER_ERROR = 'CLEAR_SERVER_ERROR';
export function clearServerError(): { type: typeof CLEAR_SERVER_ERROR } {
    return { type: CLEAR_SERVER_ERROR };
}

// all error actions
export const AUTHENTICATION_ERROR = 'AUTHENTICATION_ERROR';
export const INVALID_EMAIL = 'INVALID_EMAIL';
const FACEBOOK_LOGIN_ONLY = 'FACEBOOK_LOGIN_ONLY';
const GOOGLE_LOGIN_ONLY = 'GOOGLE_LOGIN_ONLY';
const APPLE_LOGIN_ONLY = 'APPLE_LOGIN_ONLY';
const GOOGLE_SSO_ORIGIN_ERROR = 'Not a valid origin for the client';
const SOCIAL_NETWORK_SDK = 'social network sdk';

type ErrorResponse = {
    type: typeof AUTHENTICATION_ERROR;
    payload: { serverError: ReactNode };
};
export function handleRegistrationError(payload = { message: '' }): ErrorResponse {
    const { message = '' } = payload;
    if (message.includes('CREDENTIALS_ARE_LEAKED')) {
        return {
            type: AUTHENTICATION_ERROR,
            payload: { serverError: copy.errors.credentialsLeaked },
        };
    }
    if (message.includes(FACEBOOK_LOGIN_ONLY)) {
        return {
            type: AUTHENTICATION_ERROR,
            payload: { serverError: copy.errors.facebookLoginOnly },
        };
    }
    if (message.includes(GOOGLE_LOGIN_ONLY)) {
        return {
            type: AUTHENTICATION_ERROR,
            payload: {
                serverError: copy.errors.googleLoginOnly,
            },
        };
    }
    if (message.includes(APPLE_LOGIN_ONLY)) {
        return {
            type: AUTHENTICATION_ERROR,
            payload: {
                serverError: copy.errors.appleLoginOnly,
            },
        };
    }
    if (message.includes(SOCIAL_NETWORK_SDK)) {
        return {
            type: AUTHENTICATION_ERROR,
            payload: {
                serverError: copy.errors.socialNetworkSdkLoadingFailed(message),
            },
        };
    }
    if (message.includes(flows.SOCIAL_CASE_NO_EMAIL_RETURNED)) {
        return {
            type: AUTHENTICATION_ERROR,
            payload: { serverError: copy.errors.socialLoginNoEmailReturned },
        };
    }
    if (message.includes('USER_EXISTS')) {
        return {
            type: AUTHENTICATION_ERROR,
            payload: { serverError: copy.errors.conflictRegistration },
        };
    }
    return { type: AUTHENTICATION_ERROR, payload: { serverError: copy.errors.support } };
}

export const EMAIL_ONLY_CREATE_PASSWORD = 'EMAIL_ONLY_CREATE_PASSWORD' as const;
export function handleLoginError(
    payload = {
        message: '',
        details: '',
    }
): ErrorResponse {
    const { message = '', details = '' } = payload;
    if (details.includes(GOOGLE_SSO_ORIGIN_ERROR)) {
        return {
            type: AUTHENTICATION_ERROR,
            payload: { serverError: copy.errors.googleOrigin },
        };
    }
    if (message.includes(FACEBOOK_LOGIN_ONLY)) {
        return {
            type: AUTHENTICATION_ERROR,
            payload: { serverError: copy.errors.facebookLoginOnly },
        };
    }
    if (message.includes(GOOGLE_LOGIN_ONLY)) {
        return {
            type: AUTHENTICATION_ERROR,
            payload: {
                serverError: copy.errors.googleLoginOnly,
            },
        };
    }
    if (message.includes(APPLE_LOGIN_ONLY)) {
        return {
            type: AUTHENTICATION_ERROR,
            payload: {
                serverError: copy.errors.appleLoginOnly,
            },
        };
    }
    if (message.includes(SOCIAL_NETWORK_SDK)) {
        return {
            type: AUTHENTICATION_ERROR,
            payload: {
                serverError: copy.errors.socialNetworkSdkLoadingFailed(message),
            },
        };
    }
    if (message.includes(flows.SOCIAL_CASE_NO_EMAIL_RETURNED)) {
        return {
            type: AUTHENTICATION_ERROR,
            payload: { serverError: copy.errors.socialLoginNoEmailReturned },
        };
    }
    if (message.includes('CREDENTIALS_ARE_LEAKED')) {
        return {
            type: AUTHENTICATION_ERROR,
            payload: { serverError: copy.errors.credentialsLeakedWithLink },
        };
    }
    if (message.includes('INVALID_LOGIN')) {
        return { type: AUTHENTICATION_ERROR, payload: { serverError: copy.errors.invalidLogin } };
    }
    if (message.includes('SUSPICIOUS_ACTIVITY')) {
        return {
            type: AUTHENTICATION_ERROR,
            payload: { serverError: copy.errors.suspiciousActivity },
        };
    }
    if (message.includes(EMAIL_ONLY_CREATE_PASSWORD)) {
        return {
            type: AUTHENTICATION_ERROR,
            payload: { serverError: copy.errors.emailOnlyCreatePassword },
        };
    }
    if (details.includes(INVALID_EMAIL)) {
        return {
            type: AUTHENTICATION_ERROR,
            payload: { serverError: copy.errors.requestPasswordEmailInvalid },
        };
    }
    return { type: AUTHENTICATION_ERROR, payload: { serverError: copy.errors.support } };
}
export function handleResetPasswordError(payload = { message: '' }): ErrorResponse {
    const { message = '' } = payload;
    if (message.includes('invalid password token')) {
        return {
            type: AUTHENTICATION_ERROR,
            payload: { serverError: copy.errors.invalidResetToken },
        };
    } else if (message.includes('CREDENTIALS_ARE_LEAKED')) {
        return {
            type: AUTHENTICATION_ERROR,
            payload: { serverError: copy.errors.credentialsLeaked },
        };
    } else if (message.includes('INVALID_LOGIN')) {
        return {
            type: AUTHENTICATION_ERROR,
            payload: {
                serverError: copy.errors.invalidResetPasswordWithResetSuggestion,
            },
        };
    }
    return { type: AUTHENTICATION_ERROR, payload: { serverError: copy.errors.support } };
}

export const SET_AUTH_RESPONSE = 'SET_AUTH_RESPONSE' as const;
export function setAuthResponse(res: $TSFixMe): {
    type: typeof SET_AUTH_RESPONSE;
    payload: { authResponse: typeof res };
} {
    return {
        type: SET_AUTH_RESPONSE,
        payload: { authResponse: res },
    };
}
export const SAVE_AUTH_STATE = 'SAVE_AUTH_STATE' as const;
export function saveAuthState(payload: $TSFixMe): {
    type: typeof SAVE_AUTH_STATE;
    payload: $TSFixMe;
} {
    return {
        type: SAVE_AUTH_STATE,
        payload,
    };
}
export const UPDATE_AUTH = 'UPDATE_AUTH' as const;
export function updateAuth(payload: $TSFixMe): {
    type: typeof UPDATE_AUTH;
    payload: $TSFixMe;
} {
    return {
        type: UPDATE_AUTH,
        payload,
    };
}

export const HANDLE_SOCIAL_ERROR = 'HANDLE_SOCIAL_ERROR' as const;
export function handleSocialError(error: $TSFixMe): {
    type: typeof HANDLE_SOCIAL_ERROR;
    payload: { error: typeof error };
} {
    return {
        type: HANDLE_SOCIAL_ERROR,
        payload: { error },
    };
}
