export const SALESFORCE_CHAT_KEY = 'HAS_ACTIVE_SF_CHAT';

export const DEFAULT_CHAT_LOAD_DELAY_MS = 3000;

export const CHAT_TYPE_MAP = {
    buyer: 'Buyer_Chat',
    seller: 'Seller_Chat',
    checkout: 'Checkout',
} as const;

export const SALESFORCE_LIVE_CHAT_CONTAINER_ID = 'dibs-sf-live-chat';
