import { mergeAdditionalData } from './utils';
import { getGtmEventQueue } from './gtmEventQueue';
import { eventTypeConstants } from './constants';

import { type TrackingObject } from './types';
/**
 * Social tracking using Google Tag Manager
 * @param {object} trackObj
 * @param {string} trackObj.name            The social network name
 * @param {string} trackObj.socialAction    The type of event it is firing
 * @param {object} [trackObj.additional]    An object with any additional key/value pairs to pass to GTM
 */
export function trackSocial(trackObj: TrackingObject): void {
    if (trackObj.name && trackObj.socialAction) {
        const gtmEventQueue = getGtmEventQueue();
        const gtmData: TrackingObject = mergeAdditionalData(
            {
                event: eventTypeConstants.EVENT_SOCIAL,
                network: trackObj.name,
                socialAction: trackObj.socialAction,
                opt_target: trackObj.opt_target || undefined,
                opt_pagePath: trackObj.opt_pagePath || undefined,
            },
            trackObj
        );

        gtmEventQueue.push(gtmData);
    }
}
