import { getCookieValue } from 'dibs-consented-storage';
import { COOKIE_KEYS } from './helpers/constants';
import { type Cookie, type MasqueradeUserType } from './helpers/types';

type MasqueradeValues = {
    type?: string;
    id?: string;
    vertical?: string;
};

function getMasqueradeValues(value?: string | null): MasqueradeValues | null {
    if (!value) {
        return null;
    }
    const [type, id, vertical] = value.split('_');
    const parts: MasqueradeValues = {
        type,
        id,
    };
    if (vertical) {
        parts.vertical = vertical;
    }
    return parts;
}

export function getMasqueradeSellerInfo(cookie: Cookie): MasqueradeValues | null {
    const token = getCookieValue(cookie, COOKIE_KEYS.MASQUERADE_SELLER);
    return getMasqueradeValues(token);
}

function getMasqueradeBuyerInfo(cookie: Cookie): MasqueradeValues | null {
    const token = getCookieValue(cookie, COOKIE_KEYS.MASQUERADE_BUYER);
    return getMasqueradeValues(token);
}

export function getMasqueradeSellerId(cookie: Cookie): string | null {
    const parts = getMasqueradeSellerInfo(cookie);
    if (parts && parts.vertical) {
        return `${parts.vertical.toLowerCase()}_${parts.id || ''}`;
    }
    return null;
}

export function getMasqueradeBuyerId(cookie: Cookie): string | null {
    const parts = getMasqueradeBuyerInfo(cookie);
    return parts ? parts.id || null : null;
}

export function getUserTokenString(cookie: Cookie): string | null {
    return getCookieValue(cookie, COOKIE_KEYS.USER);
}

export function getConsentedUserId(cookie: Cookie): string | null {
    return getCookieValue(cookie, COOKIE_KEYS.CONSENTED_USER_ID);
}

export function getSellerTokenString(cookie: Cookie): string | null {
    return getCookieValue(cookie, COOKIE_KEYS.SELLER);
}

export function getMasqueradeUserIdByType(
    cookie: Cookie,
    userType?: MasqueradeUserType | null
): string | null {
    // based off of bunsen/libraries/bunsen#getMasqueradeAsUserId
    // The bunsen version returned an `object.userId`
    if (userType === COOKIE_KEYS.MASQUERADE_SELLER) {
        return getMasqueradeSellerId(cookie);
    } else if (userType === COOKIE_KEYS.MASQUERADE_BUYER) {
        return getMasqueradeBuyerId(cookie);
    }
    return null;
}

export function getMasqueradeValue(
    cookie: Cookie,
    userType?: MasqueradeUserType | null
): string | null {
    return getMasqueradeUserIdByType(cookie, userType) || null;
}

export function getDirectLoginOriginalUser(cookie: Cookie): string | null {
    return getCookieValue(cookie, COOKIE_KEYS.DIRECT_LOGIN_ORIGINAL_USER);
}
