import { type WidgetParams, type InternalLoadPayload, type LoadPayload } from '../types';

type LoaderScripts = Partial<
    Record<LoadPayload | InternalLoadPayload, (options?: WidgetParams) => Promise<void>>
>;

/**
 * Scripts loader. Also it caches loading promise to load script once.
 */

export const loadScript = ({
    cachedLoaders,
    scripts,
    type,
    options,
}: {
    cachedLoaders: Partial<Record<keyof LoaderScripts, Promise<void>>>;
    scripts: LoaderScripts;
    type: keyof LoaderScripts;
    options?: WidgetParams;
}): Promise<void> => {
    let loader = cachedLoaders[type];
    if (loader) {
        return loader;
    }

    const script = scripts?.[type];

    if (!script) {
        throw new Error('Widget script for ' + type + ' is not provided.');
    }

    loader = script(options);
    cachedLoaders[type] = loader;

    return loader;
};
