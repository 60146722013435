import { useId, createContext, type FC, type ReactNode, useState, useContext } from 'react';
// DO NOT EXPORT TO CONSUMERS OUTSIDE OF DIBS-ELEMENTS
type SetLabelActive = (active: boolean) => void;

type ModalData = { labelId: string | null; modalId: string; setLabelActive: SetLabelActive };

export const ModalContext = createContext<ModalData>({
    labelId: null,
    modalId: 'Modal',
    setLabelActive: () => {},
});

export const ModalProvider: FC<{ children: ReactNode }> = ({ children }) => {
    const [labelActive, setLabelActive] = useState(false);
    const labelId = useId();
    const modalId = 'Modal' + useId();
    const activeLabelId = labelActive ? labelId : null;
    return (
        <ModalContext.Provider value={{ modalId, labelId: activeLabelId, setLabelActive }}>
            {children}
        </ModalContext.Provider>
    );
};

export const ModalConsumer: FC<{
    children: (modalData: ModalData) => ReactNode;
}> = ({ children }) => {
    const modalData = useContext(ModalContext);
    return <>{children(modalData)}</>;
};
